import Vue from 'vue'
import HoneybadgerVue from '@honeybadger-io/vue'

if (process.env.HONEYBADGER_API_KEY && process.env.NODE_ENV !== 'development') {
  const config = {
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.NODE_ENV,
    revision: process.env.VERSION,
  }

  Vue.use(HoneybadgerVue, config)
  console.info('[HONEYBADGER] Activated')
}
