<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    appVersion() {
      return this.$store.state.appVersion
    },
    accessToSwitch() {
      return this.$store.getters['access/checkIfAreaEnabled'](
        '44f93e84-f081-11ea-9cb8-cdf66828905b'
      )
    },
    accessToCompanyPanel() {
      return this.$store.getters['access/checkIfAreaEnabled'](
        '436e4f50-f081-11ea-9cb8-cdf66828905b'
      )
    },
    darkThemeToggle: {
      get() {
        return this.$vuetify.theme.dark
      },
      set(value) {
        this.$vuetify.theme.dark = value
        window.localStorage.setItem('darkTheme', value)
      },
    },
  },
  watch: {},
  mounted() {},
  methods: {
    logout() {
      this.$emit('logout')
    },
    refreshApp() {
      if (this.$store.state.app.updateAvailable) {
        this.$updateApp()
      }
      this.$router.go()
    },
    checkForUpdate() {
      this.$store.commit('notifications/addMessage', {
        message: 'Checking for update...',
      })
      navigator.serviceWorker.ready.then((registration) =>
        registration.update().then(() => {
          const reg = registration
          if (!reg.installing && !reg.waiting) {
            this.$store.commit('notifications/addMessage', {
              message: 'No Update Found',
            })
          }
        })
      )
      this.$emit('input', false)
    },
    killServiceWorker() {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        if (registrations.length > 0) {
          registrations.forEach((reg) => reg.unregister())
        }
      })
    },
  },
}
</script>

<template>
  <v-navigation-drawer
    :value="value"
    :bottom="$vuetify.breakpoint.xsOnly"
    temporary
    app
    right
    @input="$emit('input', $event)"
  >
    <v-list dense nav>
      <v-list-item
        link
        href="https://passport.oshtrak.com/settings"
        target="_blank"
      >
        <v-list-item-icon>
          <v-icon>mdi-tune</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>User Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="accessToCompanyPanel"
        :to="{ name: 'company-panel' }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-domain</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Company Panel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="accessToSwitch"
        link
        @click="
          $root.$emit('show::switch-dialog')
          $emit('input', false)
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-account-switch</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Switch Company</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'settings' }" link>
        <v-list-item-icon>
          <v-icon>mdi-cellphone-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>App Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item @click="refreshApp">
        <v-list-item-icon>
          <v-icon>mdi-refresh</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Refresh</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="checkForUpdate">
        <v-list-item-icon>
          <v-icon>mdi-update</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Check For Updates</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <v-list-item
        @click="
          $root.$emit('show::offline-dialog')
          $emit('input', false)
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Offline Download</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        @click="
          $root.$emit('show::show-saves')
          $emit('input', false)
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-content-save</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Device Saves</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-book</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Quick Start Guide</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        @click="
          $root.$emit('show::support-dialog')
          $emit('input', false)
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Contact Support</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        href="https://policies.oshtrak.com"
        target="_blank"
        rel="noopener"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-book-open-page-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Terms and Policies</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template #append>
      <div
        style="
          font-size: 0.9rem;
          padding-left: 10px;
          padding-bottom: 25px;
          text-align: left;
        "
      >
        <h4>Made in Aotearoa/New Zealand</h4>
        <h5>Version</h5>
        <span style="font-size: 0.8rem" v-text="appVersion" />
      </div>
    </template>
  </v-navigation-drawer>
</template>
