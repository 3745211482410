var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"transition":"scroll-y-transition","no-click-animation":"","internal-activator":"","max-width":"1000","scrollable":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-toolbar-title',[_vm._v("Cloud Saves")]),_vm._v(" "),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"primary accent-4"}}),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-card-text',[_c('InternetRequiredComp',{attrs:{"to":"Use Cloud Saves"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("\n              This system is intended for transfering saves from one device to\n              another. It is not a permanent storage of saves.\n            ")])]),_vm._v(" "),(_vm.showAlert)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"dismissible":"","color":"warning"},domProps:{"textContent":_vm._s(_vm.alertMessage)}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Your Cloud Saves")]),_vm._v(" "),_c('p',[_vm._v("Saves have stored in OSHTrak's Cloud Storage")]),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.loading)?_c('Loading'):_c('v-data-table',{attrs:{"items":_vm.cloudSaves,"headers":_vm.cloudSavesFields,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.saveType",fn:function(data){return [_vm._v("\n                  "+_vm._s(_vm.convertType(data.value))+"\n                ")]}},{key:"item.saveTime",fn:function(data){return [_vm._v("\n                  "+_vm._s(_vm.$toolkit.date.formatDateForReading({
                      date: data.value,
                      dateFormat: 'yyyy-MM-dd HH:mm:ss',
                      time: true,
                    }))+"\n                ")]}},{key:"item.dateuploaded",fn:function(data){return [_vm._v("\n                  "+_vm._s(_vm.$toolkit.date.formatDateForReading({
                      date: data.value,
                      dateFormat: 'yyyy-MM-dd HH:mm:ss',
                      time: true,
                    }))+"\n                ")]}},{key:"item.id",fn:function(data){return [_c('div',{staticStyle:{"float":"right"}},[_c('v-btn',{attrs:{"small":"","color":"red","dark":""},on:{"click":function($event){return _vm.deleteSaveFromCloud(data.value)}}},[_vm._v("\n                      Delete From Cloud\n                    ")]),_vm._v(" "),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.getSaveFromCloud(data.value)}}},[_vm._v("\n                      Download To Device\n                    ")])],1)]}},{key:"empty",fn:function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('h3',[_vm._v("You have no cloud saves")])])]},proxy:true}])})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Your Local Saves")]),_vm._v(" "),_c('p',[_vm._v("Saves on this device.")]),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.localSavesArr,"headers":_vm.localSavesFields,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(data){return [_vm._v("\n                  "+_vm._s(_vm.convertType(data.value))+"\n                ")]}},{key:"item.saveTime",fn:function(data){return [_vm._v("\n                  "+_vm._s(_vm.$toolkit.date.formatDateForReading({
                      date: data.value,
                      dateFormat: 'yyyy-MM-dd HH:mm:ss',
                      time: true,
                    }))+"\n                ")]}},{key:"item.saveid",fn:function(data){return [_c('div',{staticStyle:{"float":"right"}},[(_vm.isSaveInCloud(data.value, data.item.type))?_c('v-btn',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.updateSaveInCloud(data.value)}}},[_vm._v("\n                      Update Cloud Save\n                    ")]):_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.sendSaveToCloud(data.value)}}},[_vm._v("\n                      Add to Cloud Saves\n                    ")])],1)]}},{key:"empty",fn:function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('h3',[_vm._v("You have no saves on this device")])])]},proxy:true}])})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }