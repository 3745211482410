import request from './request'

const pMap = require('p-map')
// Offline

function emitStatus(e) {
  window.$nuxt.$store.commit('offlineDownload/updateStatus', e)
}

function emitProgress(e) {
  window.$nuxt.$store.commit('offlineDownload/addProgress')
  return e
}

function emitTotal(e) {
  window.$nuxt.$store.commit('offlineDownload/setMax', e.length)
  return e
}

function chunkArr(arr, len) {
  const chunks = []
  let i = 0
  const n = arr.length
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

function requestBatch(batchArr) {
  emitStatus('downloading')
  return request
    .methodRequest('/batch', 'POST', {
      // executeInSeries: true,
      includeRequestsInResponse: true,
      mergeHeaders:
        'authorization, x-requested-by, Accept, User-Agent, Morph-Company, Cookie, Set-Cookie',
      batch: batchArr.map((link) => ({
        url: link,
        method: 'GET',
      })),
    })
    .then((e) => emitProgress(e))
    .catch((error) => ({ error, batchArr }))
}

function addToCache(requests) {
  emitStatus('caching')
  const cacheName = 'api'
  return window.caches
    .open(cacheName)
    .then((cache) =>
      Promise.all(
        requests.map((y) =>
          cache.put(
            new Request(
              request.apiURL + request.removeTrailingSlash(y.request.url),
              {
                mode: 'cors',
                credentials: 'include',
                headers: new Headers(y.request.headers),
              }
            ),
            new Response(y.response.body, {
              headers: new Headers(y.response.headers),
            })
          )
        )
      )
    )
    .catch((err) => console.log(err))
}

function checkStatusCode(requests) {
  return new Promise((resolve, reject) => {
    const errors = requests.filter((x) => x.response.code > 400)
    console.log('errors', errors)
    return resolve(requests)
  })
}

function checkRequestsAgainstManifest(batchedRequests, requestManifest) {
  return new Promise((resolve, reject) => {
    const requestList = batchedRequests.map((x) => x.request.url)
    const filterList = requestManifest.filter((x) => !requestList.includes(x))
    console.log('filter list', filterList)
    return resolve(batchedRequests)
  })
}

function checkRequest(batchReq) {
  if (batchReq.error !== undefined) {
    console.log('Error Here', batchReq.batchArr)
    return requestBatch(batchReq.batchArr)
  }
  return batchReq
}

function checkForBatchRequestErrors(batchedRequests) {
  // console.log('checkForBatchRequestErrors', batchedRequests);
  // const errorArr = batchedRequests.filter(x => x.error !== undefined);
  // emitTotal(errorArr);
  return Promise.all(batchedRequests.map((x) => checkRequest(x)))
}

function checkForBatchErrors(batchedRequests, requestManifest) {
  // console.log(requestManifest);
  // console.log(batchedRequests);
  return new Promise((resolve, reject) =>
    checkStatusCode(batchedRequests)
      .then((x) =>
        checkRequestsAgainstManifest(batchedRequests, requestManifest)
      )
      .then(() => resolve(batchedRequests))
  )
}

function shuffleArray(array) {
  // console.log('before arr', array);
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  // console.log('after arr', array);

  return array
}

function getOfflineFiles(crews, sites, employees, monthsBack) {
  emitStatus('requesting')
  const ARR_CHUNKS =
    parseInt(window.localStorage.getItem('offlineChunks'), 10) || 25
  const REQUESTS_AT_ONCE =
    parseInt(window.localStorage.getItem('offlineRequests'), 10) || 15
  let requestManifest
  // Get offline manifest
  return new Promise((resolve, reject) =>
    request
      .getRequest(
        `/helper/offlinefiles?crews=${crews.map(
          (x) => x.crewid
        )}&monthsback=${monthsBack}&sites=${sites.map(
          (x) => x.id
        )}&employees=${employees.map((x) => x.id)}`
      )
      .then((x) => {
        requestManifest = x.data
      })
      // Suffle array to make sure large requests from one module aren't bundled up
      .then((arr) => {
        requestManifest = shuffleArray(requestManifest)
      })
      // spilt link array into chunks of $ARR_CHUNKS
      .then((x) => chunkArr(requestManifest, ARR_CHUNKS))
      // Emit total length of Array (total number of batch requests)
      .then((x) => emitTotal(x))
      // Run batch requests on arrs - $REQUESTS_AT_ONCE is amount of requests that can happen at once
      .then((x) => pMap(x, requestBatch, { concurrency: REQUESTS_AT_ONCE }))
      // flatten the array back down to one
      .then((x) => x.flat())
      .then((x) => checkForBatchRequestErrors(x))
      .then((x) => x.flat())
      // Go over array and check for errors
      .then((x) => checkForBatchErrors(x, requestManifest))
      // Add array of (requests, response) to cacheStorage
      .then((x) => addToCache(x))
      .then((x) => resolve(x))
      .catch((err) => reject(err))
  )
}

export default {
  getOfflineFiles,
  _addToCache: addToCache,
}
