function initFreshChat() {
  window.fcWidget.init({
    token: 'ec76e3d1-89a9-48dc-87de-6840bd80abc8',
    host: 'https://wchat.freshchat.com',
    tags: ['oshtrak-forestry'],
    siteId: 'forestry',
    config: {
      headerProperty: {
        hideChatButton: true,
      },
    },
  })
}
function initialize(i, t) {
  /* eslint-disable */
  let e;
  i.getElementById(t)
    ? initFreshChat()
    : (((e = i.createElement('script')).id = t),
      (e.async = !0),
      (e.src = 'https://wchat.freshchat.com/js/widget.js'),
      (e.onload = initFreshChat),
      i.head.appendChild(e));
}
function initiateCall() {
  initialize(document, 'freshchat-js-sdk');
}
export default ({ store }) => {
  if (process.browser) {
    window.addEventListener
      ? window.addEventListener('load', initiateCall, !1)
      : window.attachEvent('load', initiateCall, !1);
  }
};
