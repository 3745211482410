<script>
import AppBar from '@/layouts/components/AppBar'
import SettingsDrawer from '@/layouts/components/SettingsDrawer'
import MainDrawer from '@/layouts/components/MainDrawer'
import SnackbarSystem from '@/layouts/components/SnackbarSystem'

export default {
  components: {
    AppBar,
    MainDrawer,
    SnackbarSystem,
    SettingsDrawer,
  },
  middleware: ['getData', 'isAuthed', 'update'],
  data() {
    return {
      accountName: '',
      companyName: '',
      morphCompanyName: null,
      settingsDrawer: false,
      drawer: false,
      demoCompany: false,
      loading: true,
    }
  },
  fetch() {
    return this.getUserData()
  },
  computed: {
    darkThemeDisabled() {
      return window.localStorage.getItem('darkThemeDisabled') === 'true'
    },
  },
  mounted() {
    this.morphCompanyName = window.localStorage.getItem('morphCompanyName')
    this.sendOffSaves()
  },
  methods: {
    sendOffSaves() {
      const savesSent = window.localStorage.getItem('savesSent')
      // dump saves if any are v5
      this.$save
        .getAllSaves()
        .then((savesObj) =>
          Object.entries(savesObj).map((saveArr) => {
            return {
              saveId: saveArr[0],
              ...saveArr[1],
            }
          })
        )
        .then((x) => {
          return x.filter((y) => y.version === undefined)
        })
        .then((x) => {
          if (x.length > 0 && savesSent === undefined) {
            window.localStorage.setItem('savesSent', true)
            this.$save.dumpSavesToServer()
          }
        })
    },
    checkSystemTheme() {
      if (matchMedia('(prefers-color-scheme: dark)').matches) {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    },
    checkForDarkMode() {
      if (this.darkThemeDisabled !== true) {
        this.checkSystemTheme()
        const theme = localStorage.getItem('darkTheme')
        if (theme && theme === 'true') {
          this.$vuetify.theme.dark = true
        }
      }
    },
    getUserData() {
      return this.$request
        .getRequest('/')
        .then((info) => {
          const accountName = info.data.user.fullName
          const companyName = info.data.company.name
          const demoCompany = info.data.company.demo
          const companyId = info.data.company.id
          const userId = info.data.user.id
          const employeeId = info.data.user.employeeid
          const userEmail = info.data.user.email
          const companyAdmin = info.data.user.companyAdmin

          // superAdmin info.data.user.superadmin
          let profilePicture = null
          if (info.data.user.profilePictureLink) {
            profilePicture = info.data.user.profilePictureLink
          }
          // Add to layout data
          this.accountName = accountName
          this.companyName = companyName
          // Demo protection
          this.demoCompany = demoCompany
          if (this.demoCompany) {
            window.onbeforeprint = function () {
              alert('Printing is not allowed when using a DEMO ACCOUNT')
            }
          }
          // commit to state store
          this.$store.commit('addAccountInfo', {
            accountName,
            companyName,
            demoCompany,
            companyId,
            employeeId,
            userId,
            userEmail,
            companyAdmin,
            profilePicture,
          })
          if (this.$matomo) {
            this.$matomo.setUserId(userId)
            this.$matomo.setCustomVariable('1', 'CompanyId', companyId)
            this.$matomo.setCustomVariable('2', 'FullName', accountName)
            this.$matomo.setCustomVariable('3', 'CompanyName', companyName)
            this.$matomo.setCustomVariable('4', 'DemoAccount', demoCompany)
          }
        })
        .then(() => {
          this.loading = false
        })
    },
    getInitalData() {
      return Promise.all([
        this.$store.dispatch('data/crews/getCrews'),
        this.$store.dispatch('data/contractors/getContractors'),
        this.$store.dispatch('access/getAccess'),
        this.$store.dispatch('data/hazardRegister/getRegister'),
        this.$store.dispatch('data/employees/getEmployees'),
        this.$store.dispatch('data/addressBook/getAddressBook'),
      ])
    },
    logout() {
      const passportURL =
        process.env.PASSPORT_URL || 'https://passport.oshtrak.com'
      const logoutWindow = window.open(
        `${passportURL}/logout?opener=true`,
        'logoutWindow',
        'scrollbar=yes'
      )
      const timer = setInterval(() => {
        if (logoutWindow.closed) {
          this.$store.commit('removeInfo')
          window.localStorage.removeItem('morphCompany')
          window.localStorage.removeItem('morphCompanyName')
          clearInterval(timer)
          this.$router.go()
        }
      }, 1000)
    },
  },
}
</script>

<template>
  <v-app :class="demoCompany ? 'd-print' : null">
    <AppBar
      :account-name="accountName"
      :company-name="companyName"
      class="d-print-none"
      @drawerToggle="drawer = !drawer"
      @settingsDrawerToggle="settingsDrawer = !settingsDrawer"
    />
    <div class="d-none d-print-block pa-0 ma-0">
      <img
        :src="require('@/assets/images/logo/logo.png')"
        style="width: 120px"
        class="float-left"
      />
      <h6 class="float-right">
        {{ morphCompanyName || companyName }}
      </h6>
    </div>
    <MainDrawer
      v-model="drawer"
      :account-name="accountName"
      :company-name="companyName"
      @logout="logout"
    />
    <SettingsDrawer v-model="settingsDrawer" @logout="logout" />
    <v-main app>
      <Loading v-if="loading" />
      <nuxt v-else />
    </v-main>
    <SnackbarSystem />
  </v-app>
</template>

<style>
/* Print Protection when using a demo account */
@media print {
  .d-print {
    display: none;
  }

  .v-main {
    padding-top: 0 !important;
  }
}
</style>
