<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      alerts: [],
      notificationsEnabled: false,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    showDialog(value) {
      if (value) {
        this.getAlerts()
      }
    },
  },
  methods: {
    clearNotifications() {
      if (window.confirm('Are you sure you want to clear your Notifications')) {
        // this.$track.event('notifications-cleared', {
        //   event_category: 'notifications',
        //   event_label: 'num-of-notifications',
        //   value: this.alerts.length
        // });
        this.$request.methodRequest('/alerts/clear', 'POST').then((res) => {
          this.getAlerts()
        })
      }
    },
    getAlerts() {
      this.loading = true
      this.$request.getRequest('/alerts').then((res) => {
        this.alerts = res.data
        this.loading = false
      })
    },
  },
}
</script>

<template>
  <v-dialog
    v-if="showDialog"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    transition="scroll-y-transition"
    no-click-animation
    internal-activator
    max-width="800"
    scrollable
  >
    <v-card>
      <v-toolbar style="z-index: 9">
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Notifications</v-toolbar-title>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="primary accent-4"
        />
        <v-spacer />
        <v-toolbar-items>
          <v-btn text @click="clearNotifications"> Clear All </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text
        :style="$vuetify.breakpoint.mobile ? null : 'height: 500px;'"
      >
        <Loading v-if="loading" />
        <div v-else>
          <div v-if="alerts.length > 0">
            <v-row>
              <v-col v-for="alert in alerts" :key="alert.id" cols="12">
                <v-card outlined class="pa-5">
                  <h4 class="my-3" v-text="alert.message" />
                  <h5 class="float-right" v-text="alert.addedon" />
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <h2 class="text-center my-10">No Notifications</h2>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
