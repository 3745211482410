<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  mounted() {
    this.$root.$on('show::support-dialog', () => {
      this.showDialog = true
    })
  },
}
</script>

<template>
  <v-dialog
    v-if="showDialog"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="800"
    min-height="400"
  >
    <v-card>
      <v-card-text>
        <v-spacer />
        <v-row dense>
          <v-col class="text-center">
            <v-icon large>mdi-email</v-icon>
            <h3>Email Support</h3>
            <p>
              <a href="mailto:support@oshtrak.com">support@oshtrak.com</a>
            </p>
          </v-col>
          <v-col class="text-center">
            <v-icon large>mdi-phone</v-icon>
            <h3>Phone Support</h3>
            <p>
              9:00AM - 5:00PM (Mon-Fri)
              <br />
              <a href="tel:+6473765508">(07) 376 5508</a>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="showDialog = false">Close</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 30px 24px;
}
.centre {
  visibility: visible;
}
</style>
