export default function ({ store, app }) {
  const noAuthCookie = app.$cookies.get('authenticated') === undefined
  if (!store.state.initialDataLoaded && !noAuthCookie) {
    return Promise.all([
      store.dispatch('data/crews/getCrews'),
      store.dispatch('data/contractors/getContractors'),
      store.dispatch('access/getAccess'),
      store.dispatch('data/hazardRegister/getRegister'),
      store.dispatch('data/employees/getEmployees'),
      store.dispatch('data/addressBook/getAddressBook'),
    ]).then(() => store.commit('dataLoaded'))
  }
}
