<script>
export default {
  name: 'SearchDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchTerm: '',
      loading: false,
      items: [],
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    searchArr() {
      return this.$store.state.search.searchArr
    },
    searchQuery() {
      return this.searchTerm.toLowerCase()
    },
    searchResults() {
      if (this.searchTerm) {
        return this.searchArr.filter((search) =>
          search.routeName.toLowerCase().includes(this.searchQuery)
        )
      }
      return this.searchArr
    },
    selectedSearchRoute() {
      return this.searchArr.length > 0 ? this.searchResults[0].routeName : ''
    },
  },
  watch: {
    showDialog(v) {
      if (v) {
        this.$nextTick(() => {
          this.$refs.searchField.$el.focus()
        })
      }
    },
  },
  methods: {
    runRoute() {
      this.$router.push({ name: this.selectedSearchRoute })
      this.$nextTick(() => {
        this.closeDialog()
      })
    },
    closeDialog() {
      this.searchTerm = ''
      this.showDialog = false
    },
  },
}
</script>

<template>
  <v-dialog
    v-if="showDialog"
    v-model="showDialog"
    fullscreen
    transition="slide-y-transition"
    no-click-animation
    internal-activator
  >
    <v-card>
      <v-toolbar>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-text-field
          ref="searchField"
          v-model="searchTerm"
          label="Search..."
          flat
          style="margin-top: 30px"
          clearable
          autofocus
          full-width
          autocomplete="off"
          solo
          @keydown.enter="runRoute"
        />
      </v-toolbar>
      <v-list flat>
        <v-list-item
          v-for="(item, i) in searchResults"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          :class="i === 0 ? 'highlighted' : ''"
          @click="closeDialog"
        >
          <v-list-item-avatar>
            <svg
              style="
                width: 60px;
                height: 60px;
                margin: 0 auto;
                text-align: center;
                fill: #000;
              "
              viewBox="-3 -2 30 32"
            >
              <path :d="item.svgIcon" />
            </svg>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="item.name" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.highlighted {
  background-color: #f0f0f0;
}
</style>
