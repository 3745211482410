import simpleFormRequests from '@/requests/crews/simpleForm'
import dismissForm from '@/requests/crews/dismissForm'
import incidentRequests from '@/requests/incidents'
import monthlyCrewAudit from '@/requests/crews/audits'
import siteEmergencyRequests from '@/requests/sites/emergency'
import systemSignature from '@/requests/company-documents/systemSignature'
import policySignature from '@/requests/company-documents/policySignature'
import sopSignature from '@/requests/crews/sopSignature'
import safetyMeeting from '@/requests/crews/monthlyMeeting'
import safetyMeetingSignature from '@/requests/crews/monthlyMeetingSignature'
import crewGear from '@/requests/crews/crewGear'
import safeStartPlan from '@/requests/crews/safeStartPlan'
import safeStartPlanSignature from '@/requests/crews/safeStartPlanSignature'
import infrequentRequest from '@/requests/visitor/infrequent'
import addressBookRequest from '@/requests/visitor/addressBook'
import contractorVisitorRequest from '@/requests/visitor/contractor'
import addressBookVisitorSignOut from '@/requests/visitor/addressBookSignOut'
import infrequentVisitorSignOut from '@/requests/visitor/infrequentSignOut'
import contractorVisitorSignOut from '@/requests/visitor/contractorSignOut'
import emergencyDrill from '@/requests/crews/emergencyDrill'
import emergencyDrillSignature from '@/requests/crews/emergencyDrillSignature'
import systemsAudit from '@/requests/crews/systemsAudit'
import envChecklistRequests from '@/requests/sites/envChecklist'
import companyObjective from '@/requests/company-documents/objectives'
import hazardInfo from '@/requests/sites/hazardInfo'
import newHazard from '@/requests/sites/newHazard'
import checkedVerifiedControls from '@/requests/sites/checkedVerifiedControls'
import substanceInventory from '@/requests/crews/substanceInventory'
import hazardIdSignature from '@/requests/sites/hazardSignoff'
import inductionRequests from '@/requests/employees/induction'
import supportQuery from '@/requests/support'
import actionRequests from '@/requests/actions/newAction'
import signOffAction from '@/requests/actions/signOffAction'
import reviewMeeting from '@/requests/crews/health-and-safety-review/reviewMeeting'
import repReview from '@/requests/crews/health-and-safety-review/repReview'
import siteSupervisorReview from '@/requests/crews/health-and-safety-review/siteSupervisorReview'
import workerReview from '@/requests/crews/health-and-safety-review/workerReview'
import complianceRequests from '@/requests/employees/compliance'
import machineInduction from '@/requests/registers/machines/inductionQuestions'
import machinePrePurchase from '@/requests/registers/machines/prepurchase'
import machineInductedMember from '@/requests/registers/machines/newInductedMember'
import medicalQuestions from '@/requests/employees/medical'
import employeeGear from '@/requests/employees/employeeGear'
import employeeDrugConsent from '@/requests/employees/forms/drugAlcoholConsent'
import employeeInfo from '@/requests/employees/info'
import employeeDrugAlcoholRecord from '@/requests/employees/drugAlcoholRecords'
import employeeRecordRequest from '@/requests/employees/forms/recordRequest'
import employeeTrainingRecord from '@/requests/employees/trainingRecord'
import trainingCompetency from '@/requests/trainingCompetency'
import covidCodeRequest from '@/requests/sites/covidCode'

export default {
  employeeTrainingRecord,
  trainingCompetency,
  employeeDrugAlcoholRecord,
  employeeRecordRequest,
  employeeInfo,
  employeeDrugConsent,
  systemSignature,
  medicalQuestions,
  employeeGear,
  monthlyCrewAudit,
  audit: monthlyCrewAudit,
  machinePrePurchase,
  machineInductedMember,
  machineInduction,
  employeeComplianceHealthCheck: complianceRequests.healthCheck,
  employeeComplianceFirstAid: complianceRequests.firstAid,
  employeeComplianceEmergencyWardenTraining:
    complianceRequests.emergencyWardenTraining,
  workerReview,
  siteSupervisorReview,
  repReview,
  reviewMeeting,
  safetyMeeting,
  safetyMeetingSignature,
  newAction: actionRequests,
  signOffAction,
  supportQuery,
  inductionAnswer: inductionRequests.inductionAnswer,
  inductionComment: inductionRequests.inductionComment,
  crewGear,
  safeStartPlan,
  safeStartPlanSignature,
  substanceInventory,
  envChecklist: envChecklistRequests,
  systemsAudit,
  infrequentVisitorSignOut,
  contractorVisitorSignOut,
  addressBookVisitorSignOut,
  infrequentVisitor: infrequentRequest,
  addressBookVisitor: addressBookRequest,
  contractorVisitor: contractorVisitorRequest,
  dismissSbo: dismissForm,
  sbo: simpleFormRequests,
  incident: incidentRequests.newIncident,
  incidentEdit: incidentRequests.editIncident,
  siteEmergency: siteEmergencyRequests,
  siteCovidCode: covidCodeRequest,
  hazardInfo,
  newHazard,
  policySignature,
  companyObjective,
  sopSignature,
  emergencyDrill,
  emergencyDrillSignature,
  checkedVerifiedControls,
  hazardIdSignature,
}
