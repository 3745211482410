<script>
export default {
  props: {
    monthsBack: {
      type: String,
      default: '12',
    },
    selectedCrews: {
      type: Array,
      default: () => [],
    },
    selectedSites: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      siteArr: [],
      monthOpts: [
        {
          text: '6 Months',
          value: 6,
        },
        {
          text: '12 Months',
          value: 12,
        },
        {
          text: '24 Months',
          value: 24,
        },
      ],
    }
  },
  computed: {
    crewArr() {
      const arr = this.$store.state.data.crews.crewArr
      this.$emit('update:selectedCrews', arr)
      return arr
    },
    loadingData: {
      get() {
        return this.$store.state.offlineDownload.loadingData
      },
      set(v) {
        return this.$store.commit('offlineDownload/loadingData', v)
      },
    },
  },
  mounted() {
    this.getLastRequest()
    this.getSites()
  },
  methods: {
    getSites() {
      this.loadingData = true
      this.$request.getRequest('/helper/sites/').then((data) => {
        this.siteArr = data.data
        this.$emit('update:selectedSites', data.data)
        this.loadingData = false
      })
    },
    getLastRequest() {
      this.lastRequest = window.localStorage.getItem('lastOfflineRequest')
    },
  },
}
</script>

<template>
  <div class="text-center">
    <v-icon size="100"> mdi-cloud-download-outline </v-icon>
    <h1>Download Files</h1>
    <span v-if="lastRequest !== null"
      >Last Offline Download:
      {{
        $toolkit.date.formatDateForReading({
          date: lastRequest,
          dateFormat: 'yyyy-MM-dd HH:mm:ss',
          time: true,
        })
      }}</span
    >
    <b v-else>You haven't downloaded offline files</b>
    <v-select
      :disabled="loadingData"
      :value="monthsBack"
      :items="monthOpts"
      label="Months To Download"
      outlined
      class="mt-3"
      @input="$emit('update:monthsBack', $event)"
    />
    <v-combobox
      :disabled="loadingData"
      :value="selectedCrews"
      :items="crewArr"
      item-value="crewid"
      item-text="name"
      label="Crews"
      multiple
      chips
      outlined
      @input="$emit('update:selectedCrews', $event)"
    />
    <v-combobox
      :disabled="loadingData"
      :value="selectedSites"
      :items="siteArr"
      label="Sites"
      item-value="id"
      item-text="name"
      multiple
      chips
      outlined
      @input="$emit('update:selectedSites', $event)"
    />
  </div>
</template>
