import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19b81e8f = () => interopDefault(import('../pages/address-book/index.vue' /* webpackChunkName: "pages/address-book/index" */))
const _86a5e174 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _5f25583d = () => interopDefault(import('../pages/company-panel/index.vue' /* webpackChunkName: "pages/company-panel/index" */))
const _7c5739b2 = () => interopDefault(import('../pages/contractors/index.vue' /* webpackChunkName: "pages/contractors/index" */))
const _3ce94b3b = () => interopDefault(import('../pages/crews/index.vue' /* webpackChunkName: "pages/crews/index" */))
const _4b6f5664 = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _1430b4f2 = () => interopDefault(import('../pages/hazard-register/index.vue' /* webpackChunkName: "pages/hazard-register/index" */))
const _6c70782a = () => interopDefault(import('../pages/incidents/index.vue' /* webpackChunkName: "pages/incidents/index" */))
const _a6799122 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _000e26f9 = () => interopDefault(import('../pages/machines/index.vue' /* webpackChunkName: "pages/machines/index" */))
const _13a5788a = () => interopDefault(import('../pages/reminders/index.vue' /* webpackChunkName: "pages/reminders/index" */))
const _4fa817fb = () => interopDefault(import('../pages/safety-alerts/index.vue' /* webpackChunkName: "pages/safety-alerts/index" */))
const _1e7ced50 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _59039995 = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _324ec5ac = () => interopDefault(import('../pages/summary.vue' /* webpackChunkName: "pages/summary" */))
const _ec3949a2 = () => interopDefault(import('../pages/summary/index.vue' /* webpackChunkName: "pages/summary/index" */))
const _0324758f = () => interopDefault(import('../pages/summary/crews.vue' /* webpackChunkName: "pages/summary/crews" */))
const _35927e3e = () => interopDefault(import('../pages/summary/incidents.vue' /* webpackChunkName: "pages/summary/incidents" */))
const _7a2848b9 = () => interopDefault(import('../pages/training-competency/index.vue' /* webpackChunkName: "pages/training-competency/index" */))
const _755aea18 = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _e43352ec = () => interopDefault(import('../pages/company-panel/view.vue' /* webpackChunkName: "pages/company-panel/view" */))
const _0d9364e6 = () => interopDefault(import('../pages/company-panel/view/index.vue' /* webpackChunkName: "pages/company-panel/view/index" */))
const _5535e7d8 = () => interopDefault(import('../pages/company-panel/view/edit-details.vue' /* webpackChunkName: "pages/company-panel/view/edit-details" */))
const _05b6e0ca = () => interopDefault(import('../pages/company-panel/view/manage-access/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/index" */))
const _8cb4cc42 = () => interopDefault(import('../pages/company-panel/view/manage-crews.vue' /* webpackChunkName: "pages/company-panel/view/manage-crews" */))
const _1647bb9c = () => interopDefault(import('../pages/company-panel/view/manage-employees.vue' /* webpackChunkName: "pages/company-panel/view/manage-employees" */))
const _27eb085d = () => interopDefault(import('../pages/company-panel/view/manage-access/view.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view" */))
const _6e15d5c0 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/index" */))
const _7941e676 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/groups.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/groups" */))
const _7b11cb3e = () => interopDefault(import('../pages/company/view.vue' /* webpackChunkName: "pages/company/view" */))
const _4e449424 = () => interopDefault(import('../pages/company/view/index.vue' /* webpackChunkName: "pages/company/view/index" */))
const _30fccb49 = () => interopDefault(import('../pages/company/view/files.vue' /* webpackChunkName: "pages/company/view/files" */))
const _f09e1310 = () => interopDefault(import('../pages/company/view/objectives.vue' /* webpackChunkName: "pages/company/view/objectives" */))
const _a13d9760 = () => interopDefault(import('../pages/company/view/policy.vue' /* webpackChunkName: "pages/company/view/policy" */))
const _9cd1f0c8 = () => interopDefault(import('../pages/employees/new.vue' /* webpackChunkName: "pages/employees/new" */))
const _52e851f8 = () => interopDefault(import('../pages/incidents/new.vue' /* webpackChunkName: "pages/incidents/new" */))
const _71ffcf08 = () => interopDefault(import('../pages/machines/add.vue' /* webpackChunkName: "pages/machines/add" */))
const _5c77b209 = () => interopDefault(import('../pages/safety-alerts/new.vue' /* webpackChunkName: "pages/safety-alerts/new" */))
const _335a8cb0 = () => interopDefault(import('../pages/sites/register.vue' /* webpackChunkName: "pages/sites/register" */))
const _2256cf33 = () => interopDefault(import('../pages/sites/register/index.vue' /* webpackChunkName: "pages/sites/register/index" */))
const _2e67d6b8 = () => interopDefault(import('../pages/sites/register/_forestid.vue' /* webpackChunkName: "pages/sites/register/_forestid" */))
const _711a147a = () => interopDefault(import('../pages/vehicles/add.vue' /* webpackChunkName: "pages/vehicles/add" */))
const _0f91f454 = () => interopDefault(import('../pages/reminders/goto/crewgear.vue' /* webpackChunkName: "pages/reminders/goto/crewgear" */))
const _6e75fea1 = () => interopDefault(import('../pages/reminders/goto/employeegear.vue' /* webpackChunkName: "pages/reminders/goto/employeegear" */))
const _3917e8d3 = () => interopDefault(import('../pages/company-panel/manage-access/group/_groupuuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/group/_groupuuid" */))
const _6969f929 = () => interopDefault(import('../pages/company-panel/manage-access/user/_useruuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/user/_useruuid" */))
const _2c7070de = () => interopDefault(import('../pages/company/program/_versionid/index.vue' /* webpackChunkName: "pages/company/program/_versionid/index" */))
const _d29e2254 = () => interopDefault(import('../pages/company/program/_versionid/policy.vue' /* webpackChunkName: "pages/company/program/_versionid/policy" */))
const _cfa34e8e = () => interopDefault(import('../pages/employees/_id/index.vue' /* webpackChunkName: "pages/employees/_id/index" */))
const _6ec523d6 = () => interopDefault(import('../pages/incidents/_id/index.vue' /* webpackChunkName: "pages/incidents/_id/index" */))
const _358939c7 = () => interopDefault(import('../pages/sites/_siteid/index.vue' /* webpackChunkName: "pages/sites/_siteid/index" */))
const _290bc9d2 = () => interopDefault(import('../pages/contractors/_companyid/agreement/index.vue' /* webpackChunkName: "pages/contractors/_companyid/agreement/index" */))
const _2d92d8ed = () => interopDefault(import('../pages/crews/_crewid/documents.vue' /* webpackChunkName: "pages/crews/_crewid/documents" */))
const _f1fb8ca0 = () => interopDefault(import('../pages/crews/_crewid/documents/index.vue' /* webpackChunkName: "pages/crews/_crewid/documents/index" */))
const _69ba70d5 = () => interopDefault(import('../pages/crews/_crewid/documents/files.vue' /* webpackChunkName: "pages/crews/_crewid/documents/files" */))
const _19ec088c = () => interopDefault(import('../pages/crews/_crewid/documents/gear-tracker.vue' /* webpackChunkName: "pages/crews/_crewid/documents/gear-tracker" */))
const _500b5034 = () => interopDefault(import('../pages/crews/_crewid/documents/safe-start.vue' /* webpackChunkName: "pages/crews/_crewid/documents/safe-start" */))
const _3a2593f2 = () => interopDefault(import('../pages/crews/_crewid/documents/substance-inv.vue' /* webpackChunkName: "pages/crews/_crewid/documents/substance-inv" */))
const _ced8b1fc = () => interopDefault(import('../pages/crews/_crewid/monthly.vue' /* webpackChunkName: "pages/crews/_crewid/monthly" */))
const _4c936405 = () => interopDefault(import('../pages/crews/_crewid/monthly/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/index" */))
const _fc446f56 = () => interopDefault(import('../pages/crews/_crewid/monthly/audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/audits" */))
const _ed41c95e = () => interopDefault(import('../pages/crews/_crewid/monthly/emergency-drills.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/emergency-drills" */))
const _0472e9b4 = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-meetings.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-meetings" */))
const _e74e2b34 = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-review.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-review" */))
const _4f0ca490 = () => interopDefault(import('../pages/crews/_crewid/monthly/sbos.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/sbos" */))
const _01cd38ee = () => interopDefault(import('../pages/crews/_crewid/monthly/system-audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/system-audits" */))
const _b019611a = () => interopDefault(import('../pages/employees/_id/edit.vue' /* webpackChunkName: "pages/employees/_id/edit" */))
const _e08552e4 = () => interopDefault(import('../pages/employees/_id/view.vue' /* webpackChunkName: "pages/employees/_id/view" */))
const _5d338ade = () => interopDefault(import('../pages/employees/_id/view/index.vue' /* webpackChunkName: "pages/employees/_id/view/index" */))
const _200bc56c = () => interopDefault(import('../pages/employees/_id/view/compliance.vue' /* webpackChunkName: "pages/employees/_id/view/compliance" */))
const _678001f2 = () => interopDefault(import('../pages/employees/_id/view/drug-alcohol.vue' /* webpackChunkName: "pages/employees/_id/view/drug-alcohol" */))
const _97c31c94 = () => interopDefault(import('../pages/employees/_id/view/files.vue' /* webpackChunkName: "pages/employees/_id/view/files" */))
const _6e71a76e = () => interopDefault(import('../pages/employees/_id/view/forms.vue' /* webpackChunkName: "pages/employees/_id/view/forms" */))
const _9e2f9400 = () => interopDefault(import('../pages/employees/_id/view/induction.vue' /* webpackChunkName: "pages/employees/_id/view/induction" */))
const _fcf6de2c = () => interopDefault(import('../pages/employees/_id/view/linked.vue' /* webpackChunkName: "pages/employees/_id/view/linked" */))
const _6cbbc710 = () => interopDefault(import('../pages/employees/_id/view/medical.vue' /* webpackChunkName: "pages/employees/_id/view/medical" */))
const _4db5a14f = () => interopDefault(import('../pages/employees/_id/view/ppe-tracker.vue' /* webpackChunkName: "pages/employees/_id/view/ppe-tracker" */))
const _a25e83ce = () => interopDefault(import('../pages/employees/_id/view/record-learning.vue' /* webpackChunkName: "pages/employees/_id/view/record-learning" */))
const _cc2ac640 = () => interopDefault(import('../pages/employees/_id/view/training-records.vue' /* webpackChunkName: "pages/employees/_id/view/training-records" */))
const _14de1e97 = () => interopDefault(import('../pages/incidents/_id/edit.vue' /* webpackChunkName: "pages/incidents/_id/edit" */))
const _3abda846 = () => interopDefault(import('../pages/machines/_machineid/edit.vue' /* webpackChunkName: "pages/machines/_machineid/edit" */))
const _d4177e98 = () => interopDefault(import('../pages/machines/_machineid/files.vue' /* webpackChunkName: "pages/machines/_machineid/files" */))
const _2589e601 = () => interopDefault(import('../pages/machines/_machineid/induction/index.vue' /* webpackChunkName: "pages/machines/_machineid/induction/index" */))
const _8473b338 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/index.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/index" */))
const _051485fc = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/index.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/index" */))
const _83c9c880 = () => interopDefault(import('../pages/sites/_siteid/view.vue' /* webpackChunkName: "pages/sites/_siteid/view" */))
const _f2d85b7a = () => interopDefault(import('../pages/sites/_siteid/view/index.vue' /* webpackChunkName: "pages/sites/_siteid/view/index" */))
const _75d07068 = () => interopDefault(import('../pages/sites/_siteid/view/covid-qr.vue' /* webpackChunkName: "pages/sites/_siteid/view/covid-qr" */))
const _76e61361 = () => interopDefault(import('../pages/sites/_siteid/view/emergency-information.vue' /* webpackChunkName: "pages/sites/_siteid/view/emergency-information" */))
const _694c0968 = () => interopDefault(import('../pages/sites/_siteid/view/files.vue' /* webpackChunkName: "pages/sites/_siteid/view/files" */))
const _f45ee0b2 = () => interopDefault(import('../pages/sites/_siteid/view/hazard-id.vue' /* webpackChunkName: "pages/sites/_siteid/view/hazard-id" */))
const _729ed2d4 = () => interopDefault(import('../pages/sites/_siteid/view/more-forms.vue' /* webpackChunkName: "pages/sites/_siteid/view/more-forms" */))
const _5e0f9138 = () => interopDefault(import('../pages/sites/_siteid/view/visitors.vue' /* webpackChunkName: "pages/sites/_siteid/view/visitors" */))
const _a65e97ba = () => interopDefault(import('../pages/sites/_siteid/visitor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor" */))
const _01c05466 = () => interopDefault(import('../pages/sites/_siteid/visitor/index.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/index" */))
const _401e7df1 = () => interopDefault(import('../pages/sites/_siteid/visitor/addressbook.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/addressbook" */))
const _7c7783de = () => interopDefault(import('../pages/sites/_siteid/visitor/contractor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/contractor" */))
const _732ed0e2 = () => interopDefault(import('../pages/sites/_siteid/visitor/infrequent.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/infrequent" */))
const _15857613 = () => interopDefault(import('../pages/vehicles/_vehicleid/edit.vue' /* webpackChunkName: "pages/vehicles/_vehicleid/edit" */))
const _6b2b3524 = () => interopDefault(import('../pages/crews/_crewid/audits/new.vue' /* webpackChunkName: "pages/crews/_crewid/audits/new" */))
const _4d0d4aa5 = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/new.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/new" */))
const _2be7b053 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/new.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/new" */))
const _a34f9728 = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/new" */))
const _2fe00bce = () => interopDefault(import('../pages/crews/_crewid/safety-review/health-representative-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/health-representative-review" */))
const _25d5bb79 = () => interopDefault(import('../pages/crews/_crewid/safety-review/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/new" */))
const _3830728e = () => interopDefault(import('../pages/crews/_crewid/safety-review/site-supervisor-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/site-supervisor-review" */))
const _58d2a4c0 = () => interopDefault(import('../pages/crews/_crewid/safety-review/worker-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/worker-review" */))
const _49a81d96 = () => interopDefault(import('../pages/crews/_crewid/safety-sheets/add.vue' /* webpackChunkName: "pages/crews/_crewid/safety-sheets/add" */))
const _bc74da90 = () => interopDefault(import('../pages/crews/_crewid/systems-audit/new.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/new" */))
const _5b866493 = () => interopDefault(import('../pages/employees/_id/forms/da-consent.vue' /* webpackChunkName: "pages/employees/_id/forms/da-consent" */))
const _0fbc9498 = () => interopDefault(import('../pages/employees/_id/forms/rol-request.vue' /* webpackChunkName: "pages/employees/_id/forms/rol-request" */))
const _00443c32 = () => interopDefault(import('../pages/employees/_id/medical-information/edit.vue' /* webpackChunkName: "pages/employees/_id/medical-information/edit" */))
const _68f000c1 = () => interopDefault(import('../pages/employees/_id/record-learning/request.vue' /* webpackChunkName: "pages/employees/_id/record-learning/request" */))
const _54660daa = () => interopDefault(import('../pages/machines/_machineid/induction/edit.vue' /* webpackChunkName: "pages/machines/_machineid/induction/edit" */))
const _046846b2 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/new.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/new" */))
const _09fc54a4 = () => interopDefault(import('../pages/sites/_siteid/emergency-information/edit.vue' /* webpackChunkName: "pages/sites/_siteid/emergency-information/edit" */))
const _eb0ca76c = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/new.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/new" */))
const _64eba3c4 = () => interopDefault(import('../pages/sites/_siteid/hazard-id/edit.vue' /* webpackChunkName: "pages/sites/_siteid/hazard-id/edit" */))
const _4549a530 = () => interopDefault(import('../pages/sites/_siteid/presite/new.vue' /* webpackChunkName: "pages/sites/_siteid/presite/new" */))
const _4797ffce = () => interopDefault(import('../pages/crews/_crewid/sbos/new/_formid.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/new/_formid" */))
const _6dbc2910 = () => interopDefault(import('../pages/crews/_crewid/sbos/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/view/_formid/_id" */))
const _5775603e = () => interopDefault(import('../pages/crews/_crewid/audits/_id.vue' /* webpackChunkName: "pages/crews/_crewid/audits/_id" */))
const _9a5e037c = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/_drillid.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/_drillid" */))
const _1831db6d = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/_id.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/_id" */))
const _060b737b = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/_meetingid.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/_meetingid" */))
const _e3e0845c = () => interopDefault(import('../pages/crews/_crewid/systems-audit/_id.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/_id" */))
const _45081678 = () => interopDefault(import('../pages/crews/_crewid/sop/_id/_version.vue' /* webpackChunkName: "pages/crews/_crewid/sop/_id/_version" */))
const _34d2b342 = () => interopDefault(import('../pages/contractors/_companyid/_id/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/index" */))
const _b561f236 = () => interopDefault(import('../pages/contractors/_companyid/_id/view.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view" */))
const _0d9be4b0 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/index" */))
const _762d81d4 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/file.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/file" */))
const _d3850af6 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/forms.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/forms" */))
const _b6aca328 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/inductions.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/inductions" */))
const _755cba28 = () => interopDefault(import('../pages/contractors/_companyid/_id/worker-information/edit.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/worker-information/edit" */))
const _39c8d750 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/address-book",
    component: _19b81e8f,
    name: "address-book"
  }, {
    path: "/company",
    component: _86a5e174,
    name: "company"
  }, {
    path: "/company-panel",
    component: _5f25583d,
    name: "company-panel"
  }, {
    path: "/contractors",
    component: _7c5739b2,
    name: "contractors"
  }, {
    path: "/crews",
    component: _3ce94b3b,
    name: "crews"
  }, {
    path: "/employees",
    component: _4b6f5664,
    name: "employees"
  }, {
    path: "/hazard-register",
    component: _1430b4f2,
    name: "hazard-register"
  }, {
    path: "/incidents",
    component: _6c70782a,
    name: "incidents"
  }, {
    path: "/login",
    component: _a6799122,
    name: "login"
  }, {
    path: "/machines",
    component: _000e26f9,
    name: "machines"
  }, {
    path: "/reminders",
    component: _13a5788a,
    name: "reminders"
  }, {
    path: "/safety-alerts",
    component: _4fa817fb,
    name: "safety-alerts"
  }, {
    path: "/settings",
    component: _1e7ced50,
    name: "settings"
  }, {
    path: "/sites",
    component: _59039995,
    name: "sites"
  }, {
    path: "/summary",
    component: _324ec5ac,
    children: [{
      path: "",
      component: _ec3949a2,
      name: "summary"
    }, {
      path: "crews",
      component: _0324758f,
      name: "summary-crews"
    }, {
      path: "incidents",
      component: _35927e3e,
      name: "summary-incidents"
    }]
  }, {
    path: "/training-competency",
    component: _7a2848b9,
    name: "training-competency"
  }, {
    path: "/vehicles",
    component: _755aea18,
    name: "vehicles"
  }, {
    path: "/company-panel/view",
    component: _e43352ec,
    children: [{
      path: "",
      component: _0d9364e6,
      name: "company-panel-view"
    }, {
      path: "edit-details",
      component: _5535e7d8,
      name: "company-panel-view-edit-details"
    }, {
      path: "manage-access",
      component: _05b6e0ca,
      name: "company-panel-view-manage-access"
    }, {
      path: "manage-crews",
      component: _8cb4cc42,
      name: "company-panel-view-manage-crews"
    }, {
      path: "manage-employees",
      component: _1647bb9c,
      name: "company-panel-view-manage-employees"
    }, {
      path: "manage-access/view",
      component: _27eb085d,
      children: [{
        path: "",
        component: _6e15d5c0,
        name: "company-panel-view-manage-access-view"
      }, {
        path: "groups",
        component: _7941e676,
        name: "company-panel-view-manage-access-view-groups"
      }]
    }]
  }, {
    path: "/company/view",
    component: _7b11cb3e,
    children: [{
      path: "",
      component: _4e449424,
      name: "company-view"
    }, {
      path: "files",
      component: _30fccb49,
      name: "company-view-files"
    }, {
      path: "objectives",
      component: _f09e1310,
      name: "company-view-objectives"
    }, {
      path: "policy",
      component: _a13d9760,
      name: "company-view-policy"
    }]
  }, {
    path: "/employees/new",
    component: _9cd1f0c8,
    name: "employees-new"
  }, {
    path: "/incidents/new",
    component: _52e851f8,
    name: "incidents-new"
  }, {
    path: "/machines/add",
    component: _71ffcf08,
    name: "machines-add"
  }, {
    path: "/safety-alerts/new",
    component: _5c77b209,
    name: "safety-alerts-new"
  }, {
    path: "/sites/register",
    component: _335a8cb0,
    children: [{
      path: "",
      component: _2256cf33,
      name: "sites-register"
    }, {
      path: ":forestid",
      component: _2e67d6b8,
      name: "sites-register-forestid"
    }]
  }, {
    path: "/vehicles/add",
    component: _711a147a,
    name: "vehicles-add"
  }, {
    path: "/reminders/goto/crewgear",
    component: _0f91f454,
    name: "reminders-goto-crewgear"
  }, {
    path: "/reminders/goto/employeegear",
    component: _6e75fea1,
    name: "reminders-goto-employeegear"
  }, {
    path: "/company-panel/manage-access/group/:groupuuid?",
    component: _3917e8d3,
    name: "company-panel-manage-access-group-groupuuid"
  }, {
    path: "/company-panel/manage-access/user/:useruuid?",
    component: _6969f929,
    name: "company-panel-manage-access-user-useruuid"
  }, {
    path: "/company/program/:versionid",
    component: _2c7070de,
    name: "company-program-versionid"
  }, {
    path: "/company/program/:versionid?/policy",
    component: _d29e2254,
    name: "company-program-versionid-policy"
  }, {
    path: "/employees/:id",
    component: _cfa34e8e,
    name: "employees-id"
  }, {
    path: "/incidents/:id",
    component: _6ec523d6,
    name: "incidents-id"
  }, {
    path: "/sites/:siteid",
    component: _358939c7,
    name: "sites-siteid"
  }, {
    path: "/contractors/:companyid/agreement",
    component: _290bc9d2,
    name: "contractors-companyid-agreement"
  }, {
    path: "/crews/:crewid/documents",
    component: _2d92d8ed,
    children: [{
      path: "",
      component: _f1fb8ca0,
      name: "crews-crewid-documents"
    }, {
      path: "files",
      component: _69ba70d5,
      name: "crews-crewid-documents-files"
    }, {
      path: "gear-tracker",
      component: _19ec088c,
      name: "crews-crewid-documents-gear-tracker"
    }, {
      path: "safe-start",
      component: _500b5034,
      name: "crews-crewid-documents-safe-start"
    }, {
      path: "substance-inv",
      component: _3a2593f2,
      name: "crews-crewid-documents-substance-inv"
    }]
  }, {
    path: "/crews/:crewid/monthly",
    component: _ced8b1fc,
    children: [{
      path: "",
      component: _4c936405,
      name: "crews-crewid-monthly"
    }, {
      path: "audits",
      component: _fc446f56,
      name: "crews-crewid-monthly-audits"
    }, {
      path: "emergency-drills",
      component: _ed41c95e,
      name: "crews-crewid-monthly-emergency-drills"
    }, {
      path: "safety-meetings",
      component: _0472e9b4,
      name: "crews-crewid-monthly-safety-meetings"
    }, {
      path: "safety-review",
      component: _e74e2b34,
      name: "crews-crewid-monthly-safety-review"
    }, {
      path: "sbos",
      component: _4f0ca490,
      name: "crews-crewid-monthly-sbos"
    }, {
      path: "system-audits",
      component: _01cd38ee,
      name: "crews-crewid-monthly-system-audits"
    }]
  }, {
    path: "/employees/:id/edit",
    component: _b019611a,
    name: "employees-id-edit"
  }, {
    path: "/employees/:id/view",
    component: _e08552e4,
    children: [{
      path: "",
      component: _5d338ade,
      name: "employees-id-view"
    }, {
      path: "compliance",
      component: _200bc56c,
      name: "employees-id-view-compliance"
    }, {
      path: "drug-alcohol",
      component: _678001f2,
      name: "employees-id-view-drug-alcohol"
    }, {
      path: "files",
      component: _97c31c94,
      name: "employees-id-view-files"
    }, {
      path: "forms",
      component: _6e71a76e,
      name: "employees-id-view-forms"
    }, {
      path: "induction",
      component: _9e2f9400,
      name: "employees-id-view-induction"
    }, {
      path: "linked",
      component: _fcf6de2c,
      name: "employees-id-view-linked"
    }, {
      path: "medical",
      component: _6cbbc710,
      name: "employees-id-view-medical"
    }, {
      path: "ppe-tracker",
      component: _4db5a14f,
      name: "employees-id-view-ppe-tracker"
    }, {
      path: "record-learning",
      component: _a25e83ce,
      name: "employees-id-view-record-learning"
    }, {
      path: "training-records",
      component: _cc2ac640,
      name: "employees-id-view-training-records"
    }]
  }, {
    path: "/incidents/:id/edit",
    component: _14de1e97,
    name: "incidents-id-edit"
  }, {
    path: "/machines/:machineid/edit",
    component: _3abda846,
    name: "machines-machineid-edit"
  }, {
    path: "/machines/:machineid/files",
    component: _d4177e98,
    name: "machines-machineid-files"
  }, {
    path: "/machines/:machineid/induction",
    component: _2589e601,
    name: "machines-machineid-induction"
  }, {
    path: "/machines/:machineid/prepurchase",
    component: _8473b338,
    name: "machines-machineid-prepurchase"
  }, {
    path: "/sites/:siteid/enviromentalchecklist",
    component: _051485fc,
    name: "sites-siteid-enviromentalchecklist"
  }, {
    path: "/sites/:siteid/view",
    component: _83c9c880,
    children: [{
      path: "",
      component: _f2d85b7a,
      name: "sites-siteid-view"
    }, {
      path: "covid-qr",
      component: _75d07068,
      name: "sites-siteid-view-covid-qr"
    }, {
      path: "emergency-information",
      component: _76e61361,
      name: "sites-siteid-view-emergency-information"
    }, {
      path: "files",
      component: _694c0968,
      name: "sites-siteid-view-files"
    }, {
      path: "hazard-id",
      component: _f45ee0b2,
      name: "sites-siteid-view-hazard-id"
    }, {
      path: "more-forms",
      component: _729ed2d4,
      name: "sites-siteid-view-more-forms"
    }, {
      path: "visitors",
      component: _5e0f9138,
      name: "sites-siteid-view-visitors"
    }]
  }, {
    path: "/sites/:siteid/visitor",
    component: _a65e97ba,
    children: [{
      path: "",
      component: _01c05466,
      name: "sites-siteid-visitor"
    }, {
      path: "addressbook",
      component: _401e7df1,
      name: "sites-siteid-visitor-addressbook"
    }, {
      path: "contractor",
      component: _7c7783de,
      name: "sites-siteid-visitor-contractor"
    }, {
      path: "infrequent",
      component: _732ed0e2,
      name: "sites-siteid-visitor-infrequent"
    }]
  }, {
    path: "/vehicles/:vehicleid/edit",
    component: _15857613,
    name: "vehicles-vehicleid-edit"
  }, {
    path: "/crews/:crewid/audits/new",
    component: _6b2b3524,
    name: "crews-crewid-audits-new"
  }, {
    path: "/crews/:crewid/emergency-drill/new",
    component: _4d0d4aa5,
    name: "crews-crewid-emergency-drill-new"
  }, {
    path: "/crews/:crewid/safe-start-stop/new",
    component: _2be7b053,
    name: "crews-crewid-safe-start-stop-new"
  }, {
    path: "/crews/:crewid/safety-meeting/new",
    component: _a34f9728,
    name: "crews-crewid-safety-meeting-new"
  }, {
    path: "/crews/:crewid/safety-review/health-representative-review",
    component: _2fe00bce,
    name: "crews-crewid-safety-review-health-representative-review"
  }, {
    path: "/crews/:crewid/safety-review/new",
    component: _25d5bb79,
    name: "crews-crewid-safety-review-new"
  }, {
    path: "/crews/:crewid/safety-review/site-supervisor-review",
    component: _3830728e,
    name: "crews-crewid-safety-review-site-supervisor-review"
  }, {
    path: "/crews/:crewid/safety-review/worker-review",
    component: _58d2a4c0,
    name: "crews-crewid-safety-review-worker-review"
  }, {
    path: "/crews/:crewid/safety-sheets/add",
    component: _49a81d96,
    name: "crews-crewid-safety-sheets-add"
  }, {
    path: "/crews/:crewid/systems-audit/new",
    component: _bc74da90,
    name: "crews-crewid-systems-audit-new"
  }, {
    path: "/employees/:id/forms/da-consent",
    component: _5b866493,
    name: "employees-id-forms-da-consent"
  }, {
    path: "/employees/:id/forms/rol-request",
    component: _0fbc9498,
    name: "employees-id-forms-rol-request"
  }, {
    path: "/employees/:id/medical-information/edit",
    component: _00443c32,
    name: "employees-id-medical-information-edit"
  }, {
    path: "/employees/:id/record-learning/request",
    component: _68f000c1,
    name: "employees-id-record-learning-request"
  }, {
    path: "/machines/:machineid/induction/edit",
    component: _54660daa,
    name: "machines-machineid-induction-edit"
  }, {
    path: "/machines/:machineid/prepurchase/new",
    component: _046846b2,
    name: "machines-machineid-prepurchase-new"
  }, {
    path: "/sites/:siteid/emergency-information/edit",
    component: _09fc54a4,
    name: "sites-siteid-emergency-information-edit"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/new",
    component: _eb0ca76c,
    name: "sites-siteid-enviromentalchecklist-new"
  }, {
    path: "/sites/:siteid/hazard-id/edit",
    component: _64eba3c4,
    name: "sites-siteid-hazard-id-edit"
  }, {
    path: "/sites/:siteid/presite/new",
    component: _4549a530,
    name: "sites-siteid-presite-new"
  }, {
    path: "/crews/:crewid/sbos/new/:formid?",
    component: _4797ffce,
    name: "crews-crewid-sbos-new-formid"
  }, {
    path: "/crews/:crewid/sbos/view/:formid?/:id?",
    component: _6dbc2910,
    name: "crews-crewid-sbos-view-formid-id"
  }, {
    path: "/crews/:crewid/audits/:id?",
    component: _5775603e,
    name: "crews-crewid-audits-id"
  }, {
    path: "/crews/:crewid/emergency-drill/:drillid?",
    component: _9a5e037c,
    name: "crews-crewid-emergency-drill-drillid"
  }, {
    path: "/crews/:crewid/safe-start-stop/:id?",
    component: _1831db6d,
    name: "crews-crewid-safe-start-stop-id"
  }, {
    path: "/crews/:crewid/safety-meeting/:meetingid?",
    component: _060b737b,
    name: "crews-crewid-safety-meeting-meetingid"
  }, {
    path: "/crews/:crewid/systems-audit/:id?",
    component: _e3e0845c,
    name: "crews-crewid-systems-audit-id"
  }, {
    path: "/crews/:crewid/sop/:id?/:version?",
    component: _45081678,
    name: "crews-crewid-sop-id-version"
  }, {
    path: "/contractors/:companyid/:id",
    component: _34d2b342,
    name: "contractors-companyid-id"
  }, {
    path: "/contractors/:companyid/:id?/view",
    component: _b561f236,
    children: [{
      path: "",
      component: _0d9be4b0,
      name: "contractors-companyid-id-view"
    }, {
      path: "file",
      component: _762d81d4,
      name: "contractors-companyid-id-view-file"
    }, {
      path: "forms",
      component: _d3850af6,
      name: "contractors-companyid-id-view-forms"
    }, {
      path: "inductions",
      component: _b6aca328,
      name: "contractors-companyid-id-view-inductions"
    }]
  }, {
    path: "/contractors/:companyid/:id?/worker-information/edit",
    component: _755cba28,
    name: "contractors-companyid-id-worker-information-edit"
  }, {
    path: "/",
    component: _39c8d750,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
