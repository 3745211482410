import { format, parse, isValid, isAfter, addHours } from 'date-fns'

function validDate(date, dateFormat = 'dd-MM-yyyy') {
  return isValid(parse(date, dateFormat, new Date()))
}

function todayFormat(dateFormat) {
  return format(new Date(), dateFormat)
}

function formatDate(date, currentFormat, newFormat) {
  return format(parse(date, currentFormat, new Date()), newFormat)
}

// Format Date for MYSQL Format. If no date is passed, today is used.
function formatDateForDB({ date = false, dateFormat, time = false } = {}) {
  let returnFormat = 'yyyy-MM-dd'
  if (time) {
    returnFormat = 'yyyy-MM-dd HH:mm:ss'
  }
  if (date !== false) {
    if (validDate(date, dateFormat)) {
      const d = parse(date, dateFormat, new Date())
      return format(d, returnFormat)
    }
    return null
  }
  return format(new Date(), returnFormat)
}

// Format Date for People Reading. If no date is passed, today is used.
function formatDateForReading({ date = false, dateFormat, time = false } = {}) {
  let returnFormat = 'PPPP'
  if (time) {
    returnFormat = 'PPPPpp'
  }
  if (date !== false) {
    if (validDate(date, dateFormat)) {
      const d = parse(date, dateFormat, new Date())
      return format(d, returnFormat)
    }
    return 'No Date'
  }
  return format(new Date(), returnFormat)
}

function dateIsAfter(date, dateToCompare, dateFormat = 'dd-MM-yyyy') {
  return isAfter(
    parse(date, dateFormat, new Date()),
    parse(dateToCompare, dateFormat, new Date())
  )
}

function addHoursToDate(date, dateFormat = 'dd-MM-yyyy', hours) {
  const timeToAdd = parse(date, dateFormat, new Date())
  const newTime = addHours(timeToAdd, hours)
  return format(newTime, dateFormat)
}

function parseDate(date, dateFormat = 'dd-MM-yyyy') {
  return parse(date, dateFormat, new Date())
}

export default {
  formatDateForDB,
  formatDateForReading,
  validDate,
  todayFormat,
  dateIsAfter,
  formatDate,
  parseDate,
  addHours: addHoursToDate,
}
