<script>
export default {
  name: 'SwitchCompanyInfoCard',
  components: {},
  props: {
    selectedCompanyObj: {
      type: Object,
      required: true,
    },
    userCompanyName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      newNote: '',
      showNoteBox: false,
      companyNotes: [],
      crewArr: [],
    }
  },
  watch: {
    selectedCompanyObj() {
      this.getCompanySwitchInfo(true)
    },
  },
  mounted() {
    this.getCompanySwitchInfo(true)
  },
  methods: {
    getCompanySwitchInfo(shouldLoad) {
      if (
        this.selectedCompanyObj !== null &&
        this.selectedCompanyObj !== undefined
      ) {
        this.loading = shouldLoad
        this.$request
          .getRequest(`/companypanel/switch/${this.selectedCompanyObj.id}`)
          .then((res) => {
            this.crewArr = res.data.crewArr
            this.companyNotes = res.data.switchNotes
            this.loading = false
          })
      }
    },
    addCompanyNote() {
      this.$request
        .methodRequest(
          `/companypanel/switch/${this.selectedCompanyObj.id}`,
          'PUT',
          {
            note: this.newNote,
            datecreated: this.$toolkit.date.formatDateForDB({ time: true }),
          }
        )
        .then((res) => {
          this.newNote = ''
          this.showNoteBox = false
          this.getCompanySwitchInfo(false)
        })
    },
  },
}
</script>

<template>
  <div>
    <v-dialog
      v-model="showNoteBox"
      transition="scroll-y-transition"
      no-click-animation
      internal-activator
      width="500"
      scrollable
    >
      <v-card>
        <v-toolbar style="z-index: 9">
          <v-btn icon @click="showNoteBox = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Private Note To Company</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-textarea
            v-model="newNote"
            :rows="3"
            :hint="`This note will only be visible to people able to switch in ${userCompanyName}`"
            class="mt-3"
            maxlength="2500"
            counter
            no-resize
            placeholder="Private Note"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="showNoteBox = false">
            Close
          </v-btn>
          <v-spacer />
          <v-spacer />
          <v-btn color="primary" @click="addCompanyNote"> Add Note </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loading v-if="loading" />
    <v-card v-else-if="selectedCompanyObj !== null" outlined>
      <v-card-title>{{ selectedCompanyObj.name }}</v-card-title>
      <v-card-text>
        <div>
          <h4>Crew Info</h4>
          <v-row>
            <v-col v-for="crew in crewArr" :key="crew.crewid" cols="12" md="4">
              <v-card outlined>
                <v-card-title v-text="crew.crewName" />
                <v-card-text>
                  <ul class="ml-4">
                    <li
                      v-for="info in crew.infoMessageArr"
                      :key="info"
                      v-text="info"
                    />
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="9">
                  <h4>Company Notes</h4>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    color="primary"
                    small
                    class="float-right"
                    @click="showNoteBox = !showNoteBox"
                  >
                    Add Note
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <div v-if="companyNotes.length > 0">
                    <v-card
                      v-for="note in companyNotes"
                      :key="note.id"
                      class="my-2"
                      outlined
                    >
                      <v-card-text>
                        <p v-text="note.note" />
                        <h5>
                          {{ note.employeename }} - {{ note.datecreated }}
                        </h5>
                      </v-card-text>
                    </v-card>
                  </div>
                  <h4 v-else class="my-4 text-center">
                    No Switch Notes for {{ selectedCompanyObj.name }} in
                    {{ userCompanyName }}
                  </h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
