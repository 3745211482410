export default function ({ app, redirect, route, store }) {
  const areaUUIDArr = route.meta.map((meta) => {
    if (meta.auth && typeof meta.auth.areaUUID !== 'undefined') {
      return meta.auth.areaUUID
    }
    return null
  })

  const accessAllowedArr = areaUUIDArr.map((x) => {
    if (x === null) {
      return true
    }
    return store.getters['access/checkIfAreaEnabled'](x)
  })

  if (accessAllowedArr.includes(false)) {
    store.commit('notifications/addMessage', {
      message: 'No Access To Page',
    })
    return redirect('/')
  }

  const noAuthCookie = app.$cookies.get('authenticated') === undefined
  if (noAuthCookie) {
    return redirect('/login')
  }
}
