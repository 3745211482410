const newIncident = {
  name: 'newIncident',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest('/incidents/add', 'POST', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `incidents-new`, query: { sid: ctx.saveId } })
  }
};
const editIncident = {
  name: 'editIncident',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(`/incidents/edit/${data.id}`, 'POST', data)
  },
  restoreSave: (ctx) => {
  }
};

export default {
  newIncident,
  editIncident

}
