<script>
import CurrentlySwitchedChip from '@/components/switch/CurrentlySwitchedChip'
export default {
  components: {
    CurrentlySwitchedChip,
  },
  props: {
    value: {
      type: Boolean,
    },
    accountName: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDropdown: false,
      navAreas: [
        {
          areaUUID: '191be820-f081-11ea-9cb8-cdf66828905b',
          name: 'Crews',
          link: 'crews',
          icon: 'account-group',
          category: 'Dashboards',
        },
        {
          areaUUID: '1c178610-f081-11ea-9cb8-cdf66828905b',
          name: 'Sites',
          link: 'sites',
          icon: 'pine-tree',
          category: 'Dashboards',
        },
        {
          areaUUID: '1da80586-f081-11ea-9cb8-cdf66828905b',
          name: 'Employees',
          link: 'employees',
          icon: 'account',
          category: 'Dashboards',
        },
        {
          areaUUID: '22be3a86-f081-11ea-9cb8-cdf66828905b',
          name: 'Company',
          link: 'company',
          icon: 'domain',
          category: 'Dashboards',
        },
        {
          areaUUID: '25d2d4a2-f081-11ea-9cb8-cdf66828905b',
          name: 'Contractors',
          link: 'contractors',
          icon: 'contacts',
          category: 'Dashboards',
        },
        {
          areaUUID: '282935de-f081-11ea-9cb8-cdf66828905b',
          name: 'Incidents',
          link: 'incidents',
          icon: 'clipboard-check-outline',
          category: 'Registers',
        },
        {
          areaUUID: '2dd4d0a6-f081-11ea-9cb8-cdf66828905b',
          name: 'Training and Competency',
          link: 'training-competency',
          icon: 'grid',
          category: 'Registers',
        },
        {
          areaUUID: '306fffb6-f081-11ea-9cb8-cdf66828905b',
          name: 'Summary',
          link: 'summary',
          icon: 'chart-line',
          category: 'Registers',
        },
        {
          areaUUID: '32be2018-f081-11ea-9cb8-cdf66828905b',
          name: 'Reminders',
          link: 'reminders',
          icon: 'alarm',
          category: 'Registers',
        },
        {
          areaUUID: '34e71444-f081-11ea-9cb8-cdf66828905b',
          name: 'Vehicles',
          link: 'vehicles',
          icon: 'car-pickup',
          category: 'Resources',
        },
        {
          areaUUID: '36533682-f081-11ea-9cb8-cdf66828905b',
          name: 'Machines',
          link: 'machines',
          icon: 'engine',
          category: 'Resources',
        },
        {
          areaUUID: '389ea4f8-f081-11ea-9cb8-cdf66828905b',
          name: 'Address Book',
          link: 'address-book',
          icon: 'contacts',
          category: 'Resources',
        },
        {
          areaUUID: '3d290090-f081-11ea-9cb8-cdf66828905b',
          name: 'Hazard Register',
          link: 'hazard-register',
          icon: 'book-open-variant',
          category: 'Resources',
        },
        {
          areaUUID: '3f684050-f081-11ea-9cb8-cdf66828905b',
          name: 'Safety Alerts',
          link: 'safety-alerts',
          icon: 'shield-alert-outline',
          category: 'Resources',
        },
      ],
    }
  },
  computed: {
    navAreaArr() {
      const t = this.navAreas.filter((navArea) =>
        this.$store.getters['access/checkIfAreaEnabled'](navArea.areaUUID)
      )
      // Add dashboard right to the start
      t.unshift({
        name: 'Dashboard',
        link: 'index',
        category: 'Dashboards',
        icon: 'view-dashboard',
      })
      return t
    },
    navItems() {
      if (this.navAreaArr.length === 0) {
        return {}
      }
      const navObj = {}
      this.navAreaArr.forEach((item) => {
        if (navObj[item.category] === undefined) {
          navObj[item.category] = []
        }
        navObj[item.category].push(item)
      })
      return navObj
    },
  },
  watch: {},
  mounted() {},
  methods: {
    logout() {
      this.$emit('logout')
    },
  },
}
</script>

<template>
  <v-navigation-drawer
    :value="value"
    :bottom="$vuetify.breakpoint.xsOnly"
    temporary
    app
    @input="$emit('input', $event)"
  >
    <template #prepend>
      <v-list>
        <v-list-item link two-line @click="showDropdown = !showDropdown">
          <v-list-item-content>
            <v-list-item-title v-text="accountName" />
            <v-list-item-subtitle v-text="companyName" />
            <CurrentlySwitchedChip />
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list v-if="showDropdown" dense nav>
        <v-list-item href="https://passport.oshtrak.com" target="_blank" link>
          <v-list-item-icon>
            <v-icon>mdi-passport</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Open OSHTrak Passport <v-icon size="15"> mdi-open-in-new </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <v-divider />
    <v-list dense nav>
      <template>
        <div v-for="(categoryItems, category) in navItems" :key="category">
          <v-list-item-subtitle>{{ category }}</v-list-item-subtitle>

          <v-list-item
            v-for="item in categoryItems"
            :key="item.name"
            :to="{ name: item.link }"
            :exact="item.link === 'index'"
            link
            @click="$emit('input', false)"
          >
            <v-list-item-icon>
              <v-icon>{{ 'mdi-' + item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
