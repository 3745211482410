<script>
import SwitchCompanyInfoCard from './SwitchCompanyInfoCard'
export default {
  components: {
    SwitchCompanyInfoCard,
  },
  props: {
    value: {
      type: Boolean,
    },
    superAdmin: {
      type: Boolean,
      default: true,
    },
    isCompanyAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCompany: [],
      loading: true,
      companyList: [],
      saves: [],
      companySearch: '',
      currentlySwitched: false,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    companyName() {
      return this.$store.state.companyName
    },
  },
  watch: {
    showDialog(value) {
      if (value) {
        this.getAllSaves()
      }
    },
  },
  mounted() {
    this.$root.$on('show::switch-dialog', () => {
      this.showDialog = true
    })
  },
  methods: {
    checkForActiveSwitch() {
      this.currentlySwitched =
        window.localStorage.getItem('morphCompany') !== null
    },
    getAllSaves() {
      return this.$save.getAllSaves().then((saves) => {
        this.saves = Object.values(saves).map((x) => x.companyId)
        this.getSwitchableCompanies()
      })
    },
    checkForSaves(companyObj) {
      const numOfSaves = this.saves.filter((x) => x === companyObj.id).length
      companyObj.saveCount = numOfSaves
      return companyObj
    },
    activateSwitch() {
      // this.$track.event('switch-activated', {
      //   event_category: 'switch-companies',
      // });
      if (this.selectedCompany.length === 1) {
        this.loading = true
        window.localStorage.setItem('morphCompany', this.selectedCompany[0].id)
        window.localStorage.setItem(
          'morphCompanyName',
          this.selectedCompany[0].name
        )
        window.caches.delete('api')
        this.showDialog = false
        // Go to dashboard and refresh app
        window.location.assign(window.location.origin)
      }
    },
    deactivateSwitch() {
      // this.$track.event('switch-deactivated', {
      //   event_category: 'switch-companies',
      // });
      this.loading = true
      window.localStorage.removeItem('morphCompany')
      window.localStorage.removeItem('morphCompanyName')
      window.caches.delete('api')
      this.showDialog = false
      // Go to dashboard and refresh app
      window.location.assign(window.location.origin)
    },
    getSwitchableCompanies() {
      if (this.superAdmin) {
        this.$request.getRequest('/admin/companies?all=true').then((data) => {
          this.companyList = data.data.results
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((x) => x.name !== this.companyName)
            .map((x) => this.checkForSaves(x))

          this.$emit('switchPermission', true)

          this.loading = false
        })
      } else if (this.isCompanyAdmin) {
        this.$request.getRequest('/companypanel/morphlist').then((data) => {
          if (data.data.length > 0) {
            this.companyList = data.data
              .map((x) => {
                x.name = x.companyname
                x.id = x.morphcompanyid
                return x
              })
              .map((x) => this.checkForSaves(x))

            this.$emit('switchPermission', true)

            this.loading = false
          }
        })
      }
      this.checkForActiveSwitch()
    },
  },
}
</script>

<template>
  <v-dialog
    v-if="showDialog"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    transition="scroll-y-transition"
    no-click-animation
    internal-activator
    scrollable
  >
    <v-card>
      <v-toolbar style="z-index: 9">
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Switch to company</v-toolbar-title>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="primary accent-4"
        />
        <v-spacer />
      </v-toolbar>
      <Loading v-if="loading" />
      <v-card-text
        v-else-if="companyList.length === 0"
        class="text-center py-4"
      >
        <h3>No Switchable Companies Found</h3>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="12" md="3" class="mt-3">
            <v-text-field
              v-model="companySearch"
              placeholder="Search Companies"
              outlined
              type="search"
            />
            <v-card outlined>
              <v-treeview
                :active.sync="selectedCompany"
                :search="companySearch"
                :items="companyList"
                class="switch-list"
                activatable
                return-object
                hoverable
              >
                <template #prepend>
                  <v-icon> mdi-domain </v-icon>
                </template>
              </v-treeview>
            </v-card>
          </v-col>
          <v-col cols="12" md="9">
            <SwitchCompanyInfoCard
              v-if="selectedCompany.length === 1"
              :selected-company-obj="selectedCompany[0]"
              :user-company-name="companyName"
            />
            <v-card
              v-else
              outlined
              class="
                d-flex
                align-items-center
                justify-content-center
                flex-column
                mt-5
              "
            >
              <h2 class="ma-5 text-center">
                Select a company to view infomation
              </h2>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="showDialog = false"> Close </v-btn>
        <v-spacer />
        <v-btn
          v-if="currentlySwitched"
          color="red"
          text
          @click="deactivateSwitch"
        >
          Exit Switch Mode
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="selectedCompany.length === 0"
          color="primary"
          @click="activateSwitch"
        >
          Switch
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.switch-list {
  overflow-y: scroll;
  height: 50vh;

  .v-treeview-node__level {
    display: none;
  }
}
</style>
