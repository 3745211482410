<script>
export default {
  name: 'SnackbarSystem',
  data() {
    return {
      showSnackbar: false,
      timeout: 2000,
      message: '',
      notification: false,
      showUpdateButton: false,
    }
  },
  computed: {
    messages() {
      return this.$store.state.notifications.messages
    },
  },
  watch: {
    showSnackbar(e) {
      if (e === false) {
        this.removeNotification()
      }
    },
  },
  mounted() {
    this.$root.$on('notification', (n) => this.addNotification(n))
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notifications/addMessage') {
        if (this.messages.length > 0) {
          this.showNotification()
        }
      }
      if (mutation.type === 'app/appUpdateAvailable') {
        this.showNotification()
        this.addNotification({
          message: 'Update Available',
          updateButton: true,
        })
      }
    })
  },
  methods: {
    updateApp() {
      // run twice to prevent weird bug for now
      this.$updateApp()
      this.$nextTick(() => {
        this.$updateApp()
      })
    },
    addNotification(notificationObj) {
      this.$store.commit('notifications/addMessage', notificationObj)
    },
    showNotification() {
      if (this.messages.length > 0) {
        this.message = this.messages[0].message
        this.showUpdateButton = !!this.messages[0].updateButton
        this.notification = !!this.messages[0].notification
        this.showSnackbar = true
      }
    },
    removeNotification() {
      this.$store.commit('notifications/removeMessage')
      this.$nextTick(this.showNotification)
    },
  },
}
</script>

<template>
  <div class="snackbar-parent">
    <v-snackbar
      v-model="showSnackbar"
      :color="notification ? 'info' : null"
      :left="!$vuetify.breakpoint.smAndDown"
    >
      {{ message }}
      <template #action="{ attrs }">
        <v-btn
          v-if="showUpdateButton"
          v-bind="attrs"
          dark
          text
          @click="updateApp"
        >
          Update
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss" scoped>
.snackbar-parent .v-snack {
  padding-bottom: max(55px, env(safe-area-inset-bottom)) !important;
}
</style>
