<script>
import SavesDialog from '@/components/saves/SavesDialog'
import SearchDialog from '@/components/search/SearchDialog'
import SupportDialog from '@/components/SupportDialog'
import NotificationDialog from '@/components/NotificationDialog'
import SwitchDialog from '@/components/switch/SwitchDialog'
import CurrentlySwitchedChip from '@/components/switch/CurrentlySwitchedChip'
import OfflineDownloadDialog from '@/components/offline-download/OfflineDownloadDialog'

export default {
  name: 'AppBar',
  components: {
    SavesDialog,
    SearchDialog,
    SupportDialog,
    NotificationDialog,
    OfflineDownloadDialog,
    SwitchDialog,
    CurrentlySwitchedChip,
  },
  props: {
    accountName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      saveCount: 0,
      showSwitchDialog: false,
      liveChatOnline: false,
      showSupportRequest: false,
      drawer: false,
      showSaveDialog: false,
      showSearchDialog: false,
      showDownloadDialog: false,
      showNotifcationDialog: false,
      currentlySwitched: false,
      flashTimeout: null,
    }
  },
  computed: {
    switchedCompany() {
      return window.localStorage.getItem('morphCompanyName')
    },
    accessToSwitch() {
      return this.$store.getters['access/checkIfAreaEnabled'](
        '44f93e84-f081-11ea-9cb8-cdf66828905b'
      )
    },
    showExtBar() {
      return this.$store.state.appBarExtLink !== ''
    },
    barColour() {
      if (!this.$vuetify.theme.dark) {
        return '#FFF'
      }
      return null
    },
    logoAltText() {
      switch (this.$store.state.greeting) {
        case 'CHRISTMAS':
          return 'Merry Christmas, from the team at OSHTrak'
        case 'NEW_YEAR':
          return 'Happy New Year!'
        case 'OSHTRAK_BIRTHDAY':
          return 'Happy Anniversary, OSHTrak!'
        case 'ANZAC':
          return 'Lest we forget - ANZAC'
        default:
          return 'Dashboard'
      }
    },
    currentLogo() {
      switch (this.$store.state.greeting) {
        default:
          return require('@/assets/images/logo/mobile-logo.png')
      }
    },
  },
  mounted() {
    this.$root.$on('save::saving', this.flashSave)
    this.updateSaveCount()

    this.checkForActiveSwitch()
    const keyPress = (e) => {
      if (e.key === '/' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        // this.$track.event('key-combo-open', {
        //   event_category: 'module-search',
        // });
        this.openSearch()
      }
    }

    document.onkeydown = keyPress
  },
  methods: {
    backButtonAction() {
      if (this.$route.query.back) {
        return this.$router.push({
          name: this.$route.query.back,
          params: this.$route.params,
          query: { back: undefined, ...this.$route.query },
        })
      }
      return this.$router.push({
        name: this.$store.state.appBarExtLink,
        params: this.$route.params,
      })
    },
    flashSave() {
      if (this.$refs.saveIcon !== undefined && this.$refs.saveIcon.$el) {
        this.$refs.saveIcon.$el.classList.add('animate__animated')
        clearTimeout(this.flashTimeout)
        this.flashTimeout = setTimeout(() => {
          this.$refs.saveIcon.$el.classList.remove('animate__animated')
        }, 3000)
        this.updateSaveCount()
      }
    },
    updateSaveCount() {
      this.$save.getAllSaves().then((x) => {
        this.saveCount = Object.keys(x).length
      })
    },
    checkForActiveSwitch() {
      this.currentlySwitched =
        window.localStorage.getItem('morphCompany') !== null
    },
    handlePortal(newContent, old) {
      if (newContent) {
        this.showExtendedToolbar = true
      } else {
        this.showExtendedToolbar = false
      }
    },
    openSupportRequest() {
      this.showSupportRequest = true
    },
    changeLiveChatStatus(e) {
      this.liveChatOnline = e
    },
    openHelpDeskWidget() {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('open')
      }
    },
    openSearch() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.$refs.searchField.focusOnInput()
      } else {
        this.showSearchDialog = true
      }
    },
  },
}
</script>

<template>
  <div>
    <OfflineDownloadDialog v-model="showDownloadDialog" />
    <SavesDialog v-model="showSaveDialog" />
    <SearchDialog v-model="showSearchDialog" />
    <NotificationDialog v-model="showNotifcationDialog" />
    <SupportDialog
      v-model="showSupportRequest"
      @liveChatOnline="changeLiveChatStatus"
    />
    <v-lazy>
      <SwitchDialog v-model="showSwitchDialog" />
    </v-lazy>
    <v-system-bar v-if="currentlySwitched" app color="red">
      <div style="width: 100%" class="text-center text-md-left">
        <span v-if="!$vuetify.breakpoint.smAndDown"
          >Switch Active - Currently in company:
        </span>
        <span v-text="switchedCompany" />
      </div>
    </v-system-bar>
    <v-app-bar
      :extension-height="showExtBar ? 48 : 0"
      :color="barColour"
      app
      elevate-on-scroll
    >
      <v-app-bar-nav-icon class="nav-toggle" @click="$emit('drawerToggle')" />
      <nuxt-link :to="{ name: 'index' }">
        <v-img
          :src="currentLogo"
          :alt="logoAltText"
          class="mx-2"
          max-height="40"
          max-width="40"
          contain
        />
      </nuxt-link>

      <v-toolbar-title
        v-if="$vuetify.breakpoint.mdAndUp"
        @click="$router.push({ name: 'index' })"
      >
        Forestry
      </v-toolbar-title>

      <v-spacer />
      <v-spacer />
      <CurrentlySwitchedChip
        v-if="$vuetify.breakpoint.mdAndUp"
        @click="showSwitchDialog = true"
      />

      <v-btn v-if="accessToSwitch" icon @click="showSwitchDialog = true">
        <v-badge :value="currentlySwitched" color="red" dot overlap bottom>
          <v-icon>mdi-account-switch-outline</v-icon>
        </v-badge>
      </v-btn>

      <v-btn icon @click="$root.$emit('show::show-saves')">
        <v-badge :value="saveCount > 0" :content="saveCount" bottom overlap>
          <v-icon
            ref="saveIcon"
            class="animate__flash animate__slow animate__infinite"
          >
            mdi-content-save-outline
          </v-icon>
        </v-badge>
      </v-btn>
      <v-btn v icon @click="openSupportRequest">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn>
      <v-btn icon @click="showNotifcationDialog = true">
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>
      <v-btn
        class="settings-toggle"
        icon
        @click="$emit('settingsDrawerToggle')"
      >
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>

      <template v-if="showExtBar" #extension>
        <v-btn icon @click="backButtonAction">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title v-text="$store.state.appBarExtTitle" />
      </template>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar__title {
  text-decoration: none;
  cursor: pointer;
}
</style>
