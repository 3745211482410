function initialize(i, t) {
  /* eslint-disable */
    let e;
    if (!i.getElementById(t)) {
        (((e = i.createElement('script')).id = t),
            (e.defer = !0),
            (e.async = !0),
            (e.src = 'https://widget.freshworks.com/widgets/43000001243.js'),
            i.head.appendChild(e));
    }
}
function initiateCall () {
    initialize(document, 'freshdesk-js-sdk');
}
export default ({ store }) => {
    if (process.browser) {
        window.fwSettings = {
            widget_id: 43000001243
        };
        window.addEventListener
            ? window.addEventListener('load', initiateCall, !1)
            : window.attachEvent('load', initiateCall, !1);
        if (typeof window.FreshworksWidget !== 'function') {
            const n = function () {
                n.q.push(arguments);
            };
            // eslint-disable-next-line
            (n.q = []), (window.FreshworksWidget = n);
        }
        window.FreshworksWidget('hide', 'launcher');
    }
};
