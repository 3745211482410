<script>
import offlineDownload from '@/tools/offlineDownload'

import WelcomePage from './_Welcome'
import DownloadPage from './_Download'
import CompletedPage from './_Completed'

export default {
  components: {
    WelcomePage,
    DownloadPage,
    CompletedPage,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeToDownload: '',
      selectedCrews: [],
      selectedSites: [],
      monthsBack: 12,
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    offlineDownloadStatus: {
      get() {
        return this.$store.state.offlineDownload.status
      },
      set(v) {
        return this.$store.commit('offlineDownload/updateStatus', v)
      },
    },
    employees() {
      return this.$store.state.data.employees.employeeArr.map((x) => {
        x.fullName = `${x.firstname} ${x.lastname}`
        return x
      })
    },
  },
  methods: {
    getEmployees() {
      this.$request.getRequest('/helper/employees/').then((data) => {
        this.employees = data.data.map((x) => {
          x.fullName = `${x.firstname} ${x.lastname}`
          return x
        })
      })
    },
    setDownloadStatus(e) {
      switch (e) {
        case 'start':
          this.offlineDownloadStatus = 'Starting...'
          this.currentPage = 'download'
          break
        case 'requesting':
          this.offlineDownloadStatus = 'Requesting Files...'
          break
        case 'downloading':
          this.offlineDownloadStatus = 'Downloading Files...'
          break
        case 'caching':
          this.offlineDownloadStatus = 'Wrapping Up...'
          break
        case 'completed':
          this.offlineDownloadStatus = ''
          this.currentPage = 'complete'
          break
        default:
          this.offlineDownloadStatus = 'Waiting...'
      }
    },
    downloadOfflineFiles() {
      this.setDownloadStatus('start')
      const startTime = this.$toolkit.date.todayFormat('yyyy-MM-dd HH:mm:ss')
      this.$store.commit('notifications/addMessage', {
        message:
          'Downloading Offline Files. Please stay connected till this completes.',
        type: 'info',
      })
      // this.$track.event('started-downloading', {
      //   event_category: 'offline-downloading'
      // });
      offlineDownload
        .getOfflineFiles(
          this.selectedCrews,
          this.selectedSites,
          this.employees,
          this.monthsBack
        )
        .then(() => {
          this.timeToDownload = this.$moment
            .duration(
              this.$moment().diff(
                this.$moment(startTime, 'YYYY-MM-DD HH:mm:ss')
              )
            )
            .humanize()
          this.setDownloadStatus('completed')
        })
    },
  },
}
</script>

<template>
  <div class="pa-5">
    <WelcomePage
      v-if="currentPage === 'welcome'"
      :months-back.sync="monthsBack"
      :selected-sites.sync="selectedSites"
      :selected-crews.sync="selectedCrews"
    />
    <DownloadPage
      v-else-if="currentPage === 'download'"
      :download-status="offlineDownloadStatus"
    />
    <CompletedPage
      v-else-if="currentPage === 'complete'"
      :time-to-download="timeToDownload"
    />
  </div>
</template>
