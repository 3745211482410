import request from '@/tools/request'

export const state = () => ({
  accessArr: [],
  accessList: {
    enabled: 1,
    create: 2,
    update: 4,
    delete: 8, // max 15
  },
})

export const mutations = {
  addAccessArr(state, accessArr) {
    state.accessArr = accessArr
  },
}

export const getters = {
  checkIfAreaEnabled: (state, getters) => (areaUUID) => {
    return getters.checkAreaAccess(areaUUID, 'enabled')
  },
  checkAreaAccess: (state, getters) => (areaUUID, accessKey) => {
    const findArea = state.accessArr.find((x) => x.uuid === areaUUID)
    if (findArea === undefined) {
      return false
    }
    // If defined then area is enabled
    if (accessKey === 'enabled') {
      return true
    }
    const accessLevel = findArea.accessLevels[accessKey]
    if (accessLevel !== undefined) {
      return accessLevel
    }
    return findArea.parent_uuid
      ? getters.checkAreaAccess(findArea.parent_uuid, accessKey)
      : false
  },
}

export const actions = {
  getAccess({ commit }) {
    return new Promise((resolve, reject) => {
      return request
        .getRequest('/access')
        .then((data) => commit('addAccessArr', data.data))
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },
}
