<script>
export default {
  name: 'CurrentlySwitchedChip',
  props: {},
  data() {
    return {
      currentlySwitched: false,
      switchedCompany: '',
    }
  },
  mounted() {
    this.checkIfSwitched()
  },
  methods: {
    checkIfSwitched() {
      this.currentlySwitched =
        window.localStorage.getItem('morphCompany') !== null
      this.switchedCompany = window.localStorage.getItem('morphCompanyName')
    },
  },
}
</script>

<template>
  <v-chip
    v-if="currentlySwitched"
    small
    label
    color="primary"
    @click="$emit('click')"
    v-text="switchedCompany"
  />
</template>
