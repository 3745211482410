<script>
import startCase from 'lodash.startcase'

export default {
  name: 'CloudSavesModal',
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
      offlineStatus: false,
      showAlert: false,
      alertMessage: '',
      alertTimeout: null,
      loading: false,
      localSaves: {},
      cloudSaves: [],
      localSavesFields: [
        {
          value: 'type',
          text: 'Save Type',
        },
        {
          value: 'saveTime',
          text: 'Save Time',
        },
        {
          value: 'desc',
          text: 'Save Desc',
        },
        {
          value: 'saveid',
          text: ' ',
        },
      ],
      cloudSavesFields: [
        {
          value: 'saveType',
          text: 'Save Type',
        },
        {
          value: 'saveTime',
          text: 'Save Time',
        },
        {
          value: 'dateuploaded',
          text: 'Saved to Cloud on',
        },
        {
          value: 'expire',
          text: 'Deletes from Cloud on',
          formatter: (value) =>
            this.$date.formatDateForReading({
              date: value,
              dateFormat: 'yyyy-MM-dd HH:mm:ss',
              time: true,
            }),
        },
        {
          value: 'id',
          text: ' ',
        },
      ],
      checkCloudInterval: null,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    localSavesArr() {
      const localSavesKey = Object.keys(this.localSaves)

      return localSavesKey.map((saveid) => ({
        saveid,
        ...this.localSaves[saveid],
      }))
    },
    cloudSaveKeyArr() {
      return this.cloudSaves.map((cloudSave) => ({
        cloudId: cloudSave.id,
        saveKey: cloudSave.saveKey,
        saveType: cloudSave.saveType,
      }))
    },
  },
  watch: {
    showDialog(v) {
      if (v) {
        this.dialogOpenHandler()
      } else {
        this.dialogCloseHandler()
      }
    },
  },
  mounted() {
    this.checkIfOffline()
  },
  methods: {
    dialogCloseHandler() {
      clearInterval(this.checkCloudInterval)
    },
    dialogOpenHandler() {
      this.checkIfOffline()
      this.checkCloudInterval = setInterval(() => this.getSaves(false), 3000)
      // this.$track.event('cloud-saves', {
      //   event_category: 'modal'
      // });
      this.getSaves(true)
    },
    getSaves(loading) {
      this.getLocalSaves()
      this.getCloudSaves(loading)
    },
    convertType(x) {
      if (typeof x === 'string') {
        if (x === 'sbo') {
          return 'SBO'
        }
        if (x.includes('sop')) {
          return startCase(x).replace('Sop', 'SOP')
        }
        if (x.includes('Id')) {
          return startCase(x).replace('Id', 'ID')
        }
        return startCase(x)
      }
      return x
    },
    showAlertMessage(alertMessage) {
      clearTimeout(this.alertTimeout)
      this.showAlert = true
      this.alertMessage = alertMessage
      this.alertTimeout = setTimeout(() => {
        this.showAlert = false
      }, 3000)
    },
    getLocalSaves() {
      this.$save.getAllSaves().then((x) => {
        this.localSaves = x
      })
    },
    getCloudSaves(loading) {
      // if passed loading is true then show loading screen
      this.loading = loading

      this.$request.getRequest('/backups').then((res) => {
        this.cloudSaves = res.data
        this.loading = false
      })
    },
    getSaveFromCloud(cloudid) {
      this.$request.getRequest(`/backups/restore/${cloudid}`).then((res) => {
        const jsonParse =
          typeof res.data.jsonblob === 'object' && res.data.jsonblob !== null
            ? res.data.jsonblob
            : JSON.parse(res.data.jsonblob)
        this.$save.importSaves(jsonParse)
        // this.$track.event('restore-backup', {
        //   event_category: 'backup-saves',
        // });
        this.getSaves(true)
        // Tell Save Comp to update
        this.$emit('uploaded')
        this.show = false
        this.$store.commit('notifications/addMessage', {
          message: 'Download save from OSHTrak Save Cloud',
        })
      })
    },
    deleteSaveFromCloud(cloudid) {
      if (
        window.confirm(
          'Are you sure you want to delete this save from your cloud saves?'
        )
      ) {
        this.$request
          .methodRequest(`/backups/backup/${cloudid}`, 'DELETE')
          .then(() => {
            // this.$track.event('delete-backup', {
            //   event_category: 'backup-saves',
            // });
            this.getSaves(true)
            this.showAlertMessage('Cloud Save Deleted.')
          })
      }
    },
    sendSaveToCloud(saveid) {
      const saveObj = { [saveid]: this.localSaves[saveid] }

      this.$request
        .methodRequest('/backups/backup', 'PUT', {
          name: '',
          json: JSON.stringify(saveObj),
        })
        .then(() => {
          // this.$track.event('backup-saves', {
          //   event_category: 'backup-saves',
          // });
          this.getSaves(true)
          this.showAlertMessage('Cloud Save Added.')
        })
    },
    updateSaveInCloud(saveid) {
      const saveObj = this.localSaves[saveid]

      const updateObj = { [saveid]: this.localSaves[saveid] }
      const cloudId = this.isSaveInCloud(saveid, saveObj.type).cloudId

      this.$request
        .methodRequest(`/backups/backup/${cloudId}`, 'POST', {
          json: JSON.stringify(updateObj),
        })
        .then(() => {
          // this.$track.event('updated-backup-saves', {
          //   event_category: 'backup-saves',
          // });
          this.getSaves(true)
          this.showAlertMessage('Cloud Save Updated.')
        })
    },
    checkIfOffline() {
      this.$save.onlineStatus().then((x) => {
        if (x) {
          this.offlineStatus = true
        } else {
          this.offlineStatus = false
        }
      })
    },
    isSaveInCloud(saveKey, saveType) {
      return this.cloudSaveKeyArr.find(
        (obj) => obj.saveKey === saveKey && obj.saveType === saveType
      )
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    transition="scroll-y-transition"
    no-click-animation
    internal-activator
    max-width="1000"
    scrollable
  >
    <v-card>
      <v-toolbar>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cloud Saves</v-toolbar-title>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="primary accent-4"
        />
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <InternetRequiredComp to="Use Cloud Saves">
          <v-row>
            <v-col cols="12">
              <p>
                This system is intended for transfering saves from one device to
                another. It is not a permanent storage of saves.
              </p>
            </v-col>

            <v-col v-if="showAlert" cols="12">
              <v-alert dismissible color="warning" v-text="alertMessage" />
            </v-col>
            <v-col cols="12">
              <h2>Your Cloud Saves</h2>
              <p>Saves have stored in OSHTrak's Cloud Storage</p>
              <v-card outlined>
                <Loading v-if="loading" />
                <v-data-table
                  v-else
                  :items="cloudSaves"
                  :headers="cloudSavesFields"
                  disable-pagination
                  hide-default-footer
                >
                  <template #item.saveType="data">
                    {{ convertType(data.value) }}
                  </template>
                  <template #item.saveTime="data">
                    {{
                      $toolkit.date.formatDateForReading({
                        date: data.value,
                        dateFormat: 'yyyy-MM-dd HH:mm:ss',
                        time: true,
                      })
                    }}
                  </template>
                  <template #item.dateuploaded="data">
                    {{
                      $toolkit.date.formatDateForReading({
                        date: data.value,
                        dateFormat: 'yyyy-MM-dd HH:mm:ss',
                        time: true,
                      })
                    }}
                  </template>
                  <template #item.id="data">
                    <div style="float: right">
                      <v-btn
                        small
                        color="red"
                        dark
                        @click="deleteSaveFromCloud(data.value)"
                      >
                        Delete From Cloud
                      </v-btn>
                      <v-btn
                        small
                        color="primary"
                        @click="getSaveFromCloud(data.value)"
                      >
                        Download To Device
                      </v-btn>
                    </div>
                  </template>
                  <template #empty>
                    <div style="text-align: center">
                      <h3>You have no cloud saves</h3>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <h2>Your Local Saves</h2>
              <p>Saves on this device.</p>
              <v-card outlined>
                <v-data-table
                  :items="localSavesArr"
                  :headers="localSavesFields"
                  disable-pagination
                  hide-default-footer
                >
                  <template #item.type="data">
                    {{ convertType(data.value) }}
                  </template>
                  <template #item.saveTime="data">
                    {{
                      $toolkit.date.formatDateForReading({
                        date: data.value,
                        dateFormat: 'yyyy-MM-dd HH:mm:ss',
                        time: true,
                      })
                    }}
                  </template>
                  <template #item.saveid="data">
                    <div style="float: right">
                      <v-btn
                        v-if="isSaveInCloud(data.value, data.item.type)"
                        small
                        color="primary"
                        outlined
                        @click="updateSaveInCloud(data.value)"
                      >
                        Update Cloud Save
                      </v-btn>
                      <v-btn
                        v-else
                        small
                        color="primary"
                        @click="sendSaveToCloud(data.value)"
                      >
                        Add to Cloud Saves
                      </v-btn>
                    </div>
                  </template>
                  <template #empty>
                    <div style="text-align: center">
                      <h3>You have no saves on this device</h3>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </InternetRequiredComp>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-list.empty {
  margin: 10px;
  background-color: #fafafa;
  border: 2px dashed #000;
}
</style>
