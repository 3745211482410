<script>
export default {
  middleware: [],
  data() {
    return {}
  },
}
</script>

<template>
  <v-app>
    <v-main app>
      <v-container fluid class="pa-0">
        <nuxt />
      </v-container>
    </v-main>
  </v-app>
</template>
