<script>
export default {
  props: {
    downloadStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      wakeLock: null,
      needHelpButton: false,
      wakeLockTimeout: null,
    }
  },
  computed: {
    loadingValue() {
      return Math.round((this.progress / this.max) * 100)
    },
    progress() {
      return this.$store.state.offlineDownload.progress
    },
    max() {
      return this.$store.state.offlineDownload.max
    },
  },
  mounted() {
    this.showNeedHelpButton()
    this.considerEnablingWakeLock()
  },
  beforeDestroy() {
    this.disableWakeLock()
  },
  methods: {
    refreshWindow() {
      this.$router.go()
    },
    showNeedHelpButton() {
      this.needHelpButton = false
      setTimeout(() => {
        this.needHelpButton = true
      }, 20000)
    },
    considerEnablingWakeLock() {
      this.wakeLockTimeout = setTimeout(() => {
        this.enableWakeLock()
      }, 20000)
    },
    async enableWakeLock() {
      if (navigator.wakeLock !== undefined) {
        this.wakeLock = await navigator.wakeLock.request('screen')
        // this.$track.event('enabled-wake-lock', {
        //   event_category: 'offline-downloading'
        // });
      }
    },
    disableWakeLock() {
      if (this.wakeLock !== null) {
        this.wakeLock.release()
      }
    },
  },
}
</script>

<template>
  <div class="text-center">
    <v-icon size="100"> mdi-cloud-download-outline </v-icon>
    <h1 class="my-2">Downloading Files...</h1>
    <p v-if="wakeLock !== null">Wake Lock Enabled</p>
    <h4 class="my-4" v-text="downloadStatus" />
    <v-progress-linear
      :value="loadingValue"
      color="deep-purple accent-4"
      class="my-4"
      rounded
      height="25"
    >
      Downloaded {{ progress }} out of {{ max }}
    </v-progress-linear>
    <p class="mt-4">
      Do not disconnect from the internet and do not close this window/lock your
      device until this completes.
    </p>
    <p v-if="needHelpButton">
      Loading bar not moving? Try reloading
      <v-btn small color="red" @click="refreshWindow"> Reload </v-btn>
    </p>
  </div>
</template>
