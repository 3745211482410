<script>
import CameraDialog from './CameraDialog'

export default {
  name: 'PhotoPicker',
  components: {
    CameraDialog,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    tags: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPhotos: [],
      showCameraDialog: false,
      attachFilesDialog: false,
    }
  },
  computed: {
    photosArr: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    usedTags() {
      return this.photosArr.map((x) => x.tag)
    },
  },
  methods: {
    attachPhotosFromFile() {
      if (this.selectedPhotos.length !== 0) {
        this.selectedPhotos.forEach((photo) => this.addFilePhoto(photo))
        this.attachFilesDialog = false
        this.selectedPhotos = []
        this.$store.commit('notifications/addMessage', {
          message: `Photo${this.selectedPhotos.length === 1 ? '' : 's'} added`,
        })
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    addFilePhoto(photo) {
      return this.getBase64(photo)
        .then((x) => this.addPhoto(x))
        .catch((err) =>
          this.$store.commit('notifications/addMessage', { message: err })
        )
    },
    addDropFiles(e) {},
    generateTag() {
      let random
      do {
        random = Math.floor(Math.random() * (999 - 100)) + 100
      } while (
        this.usedTags.length > 0 &&
        this.usedTags.find((e) => e === random)
      )
      return random
    },
    addPhoto(photo) {
      if (this.tags) {
        this.photosArr.push({ photo, tag: this.generateTag() })
      } else {
        this.photosArr.push({ photo })
      }
    },
    deletePhoto(index) {
      if (window.confirm('Are you sure you want to delete this photo?')) {
        this.photosArr.splice(index, 1)
      }
    },
  },
}
</script>

<template>
  <div id="photo-picker">
    <CameraDialog v-model="showCameraDialog" @photo="addPhoto" />
    <v-dialog v-model="attachFilesDialog" :persistent="uploading" width="600">
      <v-card>
        <v-toolbar>
          <v-btn :disabled="uploading" icon @click="attachFilesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-progress-linear
            v-if="uploading"
            :active="uploading"
            :indeterminate="uploading"
            absolute
            bottom
            color="primary accent-4"
          />
          <v-toolbar-title>Attach Photos</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-file-input
            v-model="selectedPhotos"
            :loading="uploading"
            accept=".jpg,.png,.jpeg;capture=camera"
            class="ma-3"
            label="Attach Photos"
            hint="Accepts .png, .jpg, .jpeg"
            persistent-hint
            prepend-icon
            outlined
            multiple
            show-size
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="attachFilesDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="attachPhotosFromFile">
            Add Photos
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card outlined>
      <v-toolbar>
        <v-icon class="mr-5"> mdi-camera-burst </v-icon>
        <v-toolbar-title class="d-none d-md-block">
          Photos Picker
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text color="primary" @click="attachFilesDialog = true">
            {{ $vuetify.breakpoint.mobile ? 'Gallery' : 'Attach Photos' }}
          </v-btn>
          <v-btn text color="primary" @click="showCameraDialog = true">
            Camera
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text
        style="background-color: #fafafa"
        @drop.prevent.stop="addDropFiles"
      >
        <div v-if="photosArr.length === 0" class="text-center">
          <h3>No Photos</h3>
        </div>
        <v-row v-else>
          <v-col
            v-for="(photoObject, index) in photosArr"
            :key="index"
            class="d-flex child-flex"
            cols="12"
            md="3"
            lg="2"
          >
            <v-card>
              <v-card-text>
                <v-img :src="photoObject.photo" />
              </v-card-text>
              <v-card-actions>
                <v-chip v-if="tags" color="blue" class="white--text" label>
                  #{{ photoObject.tag }}
                </v-chip>
                <v-spacer />
                <v-btn icon color="red" @click="deletePhoto(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
