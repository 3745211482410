<script>
import OfflinePage from './pages'
export default {
  components: {
    OfflinePage,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      storage: null,
    }
  },
  computed: {
    loadingData: {
      get() {
        return this.$store.state.offlineDownload.loadingData
      },
      set(v) {
        return this.$store.commit('offlineDownload/loadingData', v)
      },
    },
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    currentOfflinePage: {
      get() {
        return this.$store.state.offlineDownload.currentPage
      },
      set(v) {
        return this.$store.commit('offlineDownload/changePage', v)
      },
    },
  },
  watch: {
    showDialog(v) {
      if (!v) {
        this.currentOfflinePage = 'welcome'
        this.$store.commit('offlineDownload/reset')
      }
    },
  },
  mounted() {
    // global event to open
    this.$root.$on('show::offline-dialog', () => {
      this.showDialog = true
    })
    this.getStorageUsage()
  },
  methods: {
    startDownload() {
      this.$refs.offlineDownloadPage.downloadOfflineFiles()
    },
    getStorageUsage() {
      if (navigator.storage !== undefined) {
        navigator.storage.estimate().then((estimate) => {
          this.storage = (estimate.usage / estimate.quota).toFixed(2)
        })
      } else {
        this.storage = null
      }
    },
  },
}
</script>

<template>
  <v-dialog
    v-if="showDialog"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="currentOfflinePage === 'download'"
    max-width="800"
  >
    <v-card>
      <v-toolbar>
        <v-btn
          v-if="currentOfflinePage !== 'download'"
          icon
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Download Offline Files</v-toolbar-title>
        <v-progress-linear
          :active="loadingData"
          :indeterminate="loadingData"
          absolute
          bottom
          color="primary accent-4"
        />
      </v-toolbar>
      <v-card-text>
        <InternetRequiredComp to="download offline files">
          <OfflinePage ref="offlineDownloadPage" v-model="currentOfflinePage" />
        </InternetRequiredComp>
      </v-card-text>
      <v-card-actions v-if="currentOfflinePage !== 'download'">
        <v-btn text @click="showDialog = false"> Close </v-btn>
        <v-spacer />
        <v-btn
          v-if="currentOfflinePage === 'welcome'"
          :disabled="loadingData"
          color="primary"
          @click="startDownload"
        >
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
