import request from '@/tools/request'

export const state = () => ({
  tasks: [],
  risks: [],
  controls: [],
})

export const mutations = {
  addHazardRegister(state, hazardDataObj) {
    state.tasks = hazardDataObj.tasks
    state.risks = hazardDataObj.risks
    state.controls = hazardDataObj.controls
  },
}

export const getters = {
  getRisksFromTaskId: (state) => (taskid) => {
    return state.risks.filter((x) => x.taskid === taskid)
  },
  getControlsFromRiskId: (state) => (riskid) => {
    return state.controls.filter((x) => x.riskid === riskid)
  },
}

export const actions = {
  getRegister({ commit }) {
    return request.getRequest('/hazards/add/register').then((res) => {
      commit('addHazardRegister', res.data)
    })
  },
}
