<script>
import CloudSaveDialog from '@/components/saves/CloudSaveDialog'
import startCase from 'lodash.startcase'

export default {
  components: {
    CloudSaveDialog,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      otherCompanySaves: false,
      uploadAllDialogToggle: false,
      loading: true,
      savesArr: [],
      showMiniSaves: true,
      showCloudSavesDialog: false,
      headers: [
        {
          value: 'saveType',
          text: 'Save Type',
        },
        {
          value: 'saveTime',
          text: 'Last Edited',
        },
        {
          value: 'timeLeft',
          text: 'Save Will Delete In',
        },
        {
          value: 'status',
          text: 'Status',
        },
        {
          value: 'id',
          text: '',
        },
      ],
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    saves() {
      return this.savesArr
        .filter((save) => {
          if (save.mini === false || save.mini === undefined) {
            return true
          }
          if (save.mini === true && this.showMiniSaves) {
            return true
          }
          return false
        })
        .sort((a, b) =>
          this.$toolkit.date.dateIsAfter(
            a.saveTime,
            b.saveTime,
            'yyyy-MM-dd HH:mm:ss'
          )
            ? -1
            : 1
        )
    },
  },
  watch: {
    showDialog(value) {
      if (value) {
        this.getSaves()
      }
    },
  },
  mounted() {
    this.$root.$on('show::show-saves', () => {
      this.showDialog = true
    })
  },
  methods: {
    convertType(x) {
      if (x === 'sbo') {
        return 'SBO'
      }
      if (x.includes('sop')) {
        return startCase(x).replace('Sop', 'SOP')
      }
      if (x.includes('Id')) {
        return startCase(x).replace('Id', 'ID')
      }
      return startCase(x)
    },
    openSave(saveId) {
      this.$save.restoreSave(saveId)
      this.showDialog = false
    },
    deleteSave(saveId) {
      if (window.confirm('Are you sure you want to delete this save?')) {
        this.$save.removeSave(saveId).then(() => this.getSaves())
      }
    },
    uploadAllSaves() {
      if (window.confirm('Upload all pending saves?')) {
        this.$save.uploadAllSaves().then((x) => {
          this.$store.commit('notifications/addMessage', {
            message: 'Uploaded Pending Saves',
          })
          this.getSaves()
        })
      }
    },
    uploadSave(saveId) {
      if (window.confirm('Upload this save?')) {
        this.$save.uploadSavev2(saveId).then(() => this.getSaves())
      }
    },
    getSaves() {
      this.loading = true
      return this.$save
        .getAllSaves()
        .then((saveObj) => {
          const saveObjKeys = Object.keys(saveObj)
          this.savesArr = saveObjKeys
            .map((id) => ({ id, ...saveObj[id] }))
            .map((saveObj) => {
              saveObj.saveType = this.convertType(saveObj.type)
              return saveObj
            })

          this.loading = false
        })
        .then(() => this.$save.checkIfOtherCompanySaves())
        .then((otherSaves) => {
          this.otherCompanySaves = otherSaves
        })
    },
  },
}
</script>

<template>
  <v-dialog
    v-if="showDialog"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    transition="scroll-y-transition"
    no-click-animation
    internal-activator
    max-width="800"
    scrollable
  >
    <CloudSaveDialog
      v-model="showCloudSavesDialog"
      :saves-arr="savesArr"
      @uploaded="getSaves"
    />
    <v-card>
      <v-toolbar>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Saves</v-toolbar-title>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="primary accent-4"
        />
        <v-spacer />
        <v-toolbar-items>
          <v-btn text @click="showCloudSavesDialog = true"> Cloud Saves </v-btn>
          <v-btn text @click.stop="uploadAllSaves"> Upload All </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="ma-0 pa-0">
        <Loading v-if="loading" />
        <div v-else class="mt-4">
          <v-alert v-if="otherCompanySaves" color="red" class="mx-3" dark>
            There are some saves stored in other OSHTrak companies on this
            device. Please switch into the correct company to store, view and
            upload the correct saves.
          </v-alert>
          <div v-if="saves.length > 0">
            <v-list subheader three-line>
              <v-list-item
                v-for="save in saves"
                :key="save.id"
                :dense="save.mini"
                @click="openSave(save.id)"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ save.saveType }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <i v-text="save.desc" />
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Last Edited: {{ save.saveTime }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="$vuetify.breakpoint.smAndDown">
                    <v-list-item-action>
                      <v-btn
                        color="red"
                        dark
                        small
                        @click.stop="deleteSave(save.id)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn
                        v-if="save.autoUpload || save.uploadable"
                        color="primary"
                        outlined
                        small
                        @click.stop="uploadSave(save.id)"
                      >
                        Upload
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action class="float-right">
                      <v-btn
                        v-if="!save.mini"
                        color="primary"
                        small
                        @click.stop="openSave(save.id)"
                      >
                        Open Save
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <div v-if="!$vuetify.breakpoint.smAndDown">
                  <v-list-item-action>
                    <v-btn
                      color="red"
                      dark
                      small
                      @click.stop="deleteSave(save.id)"
                    >
                      Delete
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn
                      v-if="save.autoUpload || save.uploadable"
                      color="primary"
                      outlined
                      small
                      @click.stop="uploadSave(save.id)"
                    >
                      Upload
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn
                      v-if="!save.mini"
                      color="primary"
                      small
                      @click.stop="openSave(save.id)"
                    >
                      Open Save
                    </v-btn>
                  </v-list-item-action>
                </div>
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="my-10 text-center">
            <v-icon size="80"> mdi-content-save-outline </v-icon>
            <h1 class="my-2">No Device Saves</h1>
            <h5 v-if="!showMiniSaves">Mini saves hidden</h5>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-switch
          v-model="showMiniSaves"
          class="mx-2"
          label="Show Mini Saves"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
