<script>
export default {
  props: {
    timeToDownload: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      storage: null,
    }
  },
  mounted() {
    this.getStorageUsage()
  },
  methods: {
    getStorageUsage() {
      if (navigator.storage !== undefined) {
        navigator.storage.estimate().then((estimate) => {
          this.storage = (estimate.usage / estimate.quota).toFixed(2)
        })
      }
    },
  },
}
</script>

<template>
  <div class="text-center">
    <v-icon class="animate__animated animate__tada" size="200" color="success">
      mdi-check-all
    </v-icon>
    <h2 class="my-2">Download Completed!</h2>
    <p>You can now close this window and go offline.</p>
    <p />
    <h6 v-if="storage">
      OSHTrak is currently using {{ storage }}% of aligated storage
    </h6>
    <h6>Time to download: {{ timeToDownload }}</h6>
  </div>
</template>
