<script>
export default {
  name: 'SignatureDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      },
    },
  },
  methods: {
    submitSignature() {
      const signature = this.$refs.signature.getSignature()
      this.$emit('signed', signature)
      this.showDialog = false
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="showDialog"
    transition="scroll-y-transition"
    no-click-animation
    internal-activator
    max-width="800"
    scrollable
  >
    <v-card>
      <v-toolbar>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Sign Off </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col v-if="showDialog" cols="12" class="text-center">
            <label>Sign Here:</label>
            <SignatureBox ref="signature" />
          </v-col>
          <v-row />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          size="sm"
          class="float-right"
          @click="submitSignature"
        >
          Sign Off
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
