import request from '@/tools/request'

export const state = () => ({
  crewArr: [],
})

export const mutations = {
  addCrews(state, crewArr) {
    state.crewArr = crewArr
  },
}

export const getters = {
  crewColour: (state) => (crewid) => {
    const findCrew = state.crewArr.find((x) => x.crewid === crewid)
    return findCrew ? findCrew.colour : '000'
  },
  crewDetails: (state) => (crewid) => {
    const findCrew = state.crewArr.find(
      (x) => String(x.crewid) === String(crewid)
    )
    return findCrew || {}
  },
}

export const actions = {
  getCrews({ commit }) {
    return new Promise((resolve, reject) => {
      return request
        .getRequest('/helper/crews')
        .then((data) => commit('addCrews', data.data))
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },
}
